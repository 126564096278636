import React, { useCallback, useEffect, useState } from 'react';
import { TableFilterCardContainer } from 'app/WMS/styles/styles';
import DetailsItem from 'components/Tools/DetailsItem';
import { Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Tools/Loader';
import { Container } from './styles';
import { getIntegrationData } from './api/integration.api';
import { IntegrationDataProps } from './types';
import { IntegrationTable } from './components/IntegrationTable';

export const Integration = () => {
  const [integrations, setIntegrations] = useState<IntegrationDataProps[]>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(false);

  const { t } = useTranslation();

  const getIntegrations = useCallback(async (integrationStatus?: string) => {
    setIsLoading(true);
    try {
      await getIntegrationData({ status: integrationStatus })
        .then(({ data }) => {
          setIntegrations(data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const filterIntegrationStatus = (status?: number) => {
    const i = integrations;
    switch (status) {
      case 1: {
        const resultsReceive = i.filter(item => {
          return item.status === 'RUNNING';
        });
        return String(resultsReceive.length);
      }
      case 2: {
        const resultsReceive = i.filter(item => {
          return item.status === 'STOPPED';
        });
        return String(resultsReceive.length);
      }
      case 3: {
        const resultsReceive = i.filter(item => {
          return item.status === 'ERROR';
        });
        return String(resultsReceive.length);
      }
      default:
        return String(i.length);
    }
  };

  useEffect(() => {
    getIntegrations();
  }, []);

  return (
    <Container>
      <div className="integrationWrapper">
        <div className="integrationHeader">
          <p>Monitoramento</p>
        </div>
        <Divider />
        <div className="integrationContent">
          {isLoading && <Loader />}
          {!isLoading && (
            <>
              <div className="integrationSubHeader">
                <p>Processos</p>
              </div>
              <TableFilterCardContainer className="customFilterCardContainer">
                <div>
                  <button
                    type="button"
                    onClick={() => getIntegrations('RUNNING')}
                  >
                    <DetailsItem
                      id="done"
                      card
                      title={String(t('screens.integration.running'))}
                      value={filterIntegrationStatus(1)}
                    />
                  </button>
                  <button
                    type="button"
                    onClick={() => getIntegrations('STOPPED')}
                  >
                    <DetailsItem
                      id="pending"
                      card
                      title={String(t('screens.integration.stopped'))}
                      value={filterIntegrationStatus(2)}
                    />
                  </button>
                  <button
                    type="button"
                    onClick={() => getIntegrations('ERROR')}
                  >
                    <DetailsItem
                      id="divergent"
                      card
                      title={String(t('screens.integration.error'))}
                      value={filterIntegrationStatus(3)}
                    />
                  </button>
                </div>
              </TableFilterCardContainer>
              <IntegrationTable integrationList={integrations} />
            </>
          )}
        </div>
      </div>
    </Container>
  );
};
