import React, { lazy } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { ChangeLog } from 'features/ChangeLog';
import { Integration } from 'features/Integration';
import FollowProductAge from 'features/FollowProductAge';
import FollowWave from 'features/FollowWave';
import Route from './route';

import Error400 from '../pages/Errors/400';
import Error401 from '../pages/Errors/401';
import Pallet from '../app/WMS/resources/Reports/Pallet';

import Token from '../pages';
import ProdutivityExpedition from '../app/WMS/resources/Reports/ProductivityExpedition';
import CreateComposition from '../app/WMS/resources/Composition/CreateComposition';
import StoreReceivement from '../app/Store/resources/StoreReceivement';

const Home = lazy(() => import('features/Home'));
const ReportKardex = lazy(() => import('features/ReportKardex'));
const Composition = lazy(() => import('features/Composition'));
const PickingFollow = lazy(() => import('features/Picking/FollowPicking'));
const OrderReverse = lazy(() => import('features/Order/Reverse'));
const NewReceivement = lazy(() => import('features/Receivement'));
const RegisterOrder = lazy(() => import('features/Order/Register'));
const Wave = lazy(() => import('features/Picking/Wave'));
const Address = lazy(() => import('features/Storage/Addresses'));
const Conference = lazy(() => import('features/Picking/Conference'));
const Boarding = lazy(() => import('features/Boarding'));
const Query = lazy(() => import('features/Expedition'));
const Inventory = lazy(() => import('features/Inventory'));
const NewFollow = lazy(() => import('features/Order/Follow'));
const Insufficient = lazy(() => import('features/Insufficient'));
const Transference = lazy(() => import('features/Transference'));
const Ressuply = lazy(() => import('features/Resupply'));
const Box = lazy(() => import('features/Box'));
const ProdutivityConference = lazy(
  () => import('features/ProductivityConference')
);

const ProdutivityStorage = lazy(() => import('features/ProductivityStorage'));
const Components = lazy(() => import('features/Components'));
const ReportReceivement = lazy(() => import('features/ReportReceivement'));
const InvoiceDivergent = lazy(() => import('features/InvoiceDivergent'));
const ReportSeparation = lazy(() => import('features/ReportSeparation'));
const ReportStock = lazy(() => import('features/ReportStock'));
const ReportConference = lazy(() => import('features/ReportConference'));
const NewLog = lazy(() => import('features/Log/newLog'));
const Structure = lazy(() => import('features/Storage/Structure'));
const Dashboard = lazy(() => import('features/Dashboard'));
const Parameters = lazy(() => import('features/Parametes'));
const RegisterProduct = lazy(() => import('features/Product/Register'));

const routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" exact component={Token} />
        <Route path="/wms/" exact isInside isPrivate component={Dashboard} />
        <Route path="/wms/home/" exact isInside isPrivate component={Home} />
        <Route
          path="/wms/structure_addresses"
          exact
          component={Structure}
          isPrivate
          isInside
        />
        <Route
          path="/wms/picking"
          exact
          component={PickingFollow}
          isPrivate
          isInside
        />
        <Route
          path="/wms/pickingConference"
          exact
          component={PickingFollow}
          isPrivate
          isInside
        />
        <Route
          path="/wms/pickingSeparation"
          exact
          component={PickingFollow}
          isPrivate
          isInside
        />
        <Route
          path="/wms/resupply"
          exact
          component={Ressuply}
          isPrivate
          isInside
        />
        <Route
          path="/wms/picking/:codeStatus"
          exact
          component={PickingFollow}
          isPrivate
          isInside
        />
        <Route
          path="/wms/wave/create"
          exact
          component={Wave}
          isPrivate
          isInside
        />
        <Route
          path="/wms/conference"
          exact
          component={Conference}
          isPrivate
          isInside
        />
        <Route
          path="/wms/register_product"
          exact
          component={RegisterProduct}
          isInside
          isPrivate
        />
        <Route
          path="/wms/followOrder"
          exact
          component={NewFollow}
          isPrivate
          isInside
        />
        <Route
          path="/wms/followOrder/:codeStatus"
          exact
          component={NewFollow}
          isPrivate
          isInside
        />
        <Route
          path="/wms/reverseOrder"
          exact
          component={OrderReverse}
          isPrivate
          isInside
        />
        <Route
          path="/wms/register_order"
          exact
          component={RegisterOrder}
          isPrivate
          isInside
        />
        <Route
          path="/wms/addresses"
          exact
          component={Address}
          isPrivate
          isInside
        />
        <Route path="/wms/log" exact component={NewLog} isPrivate isInside />
        <Route
          path="/wms/receive/"
          exact
          component={NewReceivement}
          isPrivate
          isInside
        />
        <Route
          path="/receiving/receive/"
          exact
          component={StoreReceivement}
          isPrivate
          isInside
        />
        <Route
          path="/wms/Insufficient"
          exact
          component={Insufficient}
          isPrivate
          isInside
        />
        <Route
          path="/wms/transference"
          exact
          component={Transference}
          isPrivate
          isInside
        />
        <Route
          path="/wms/ProdutivityStorage"
          exact
          component={ProdutivityStorage}
          isPrivate
          isInside
        />
        <Route
          path="/wms/ProdutivityConference"
          exact
          component={ProdutivityConference}
          isPrivate
          isInside
        />
        <Route
          path="/wms/produtivityExpedition"
          exact
          component={ProdutivityExpedition}
          isPrivate
          isInside
        />
        <Route
          path="/wms/expedition"
          exact
          component={Query}
          isPrivate
          isInside
        />
        <Route
          path="/wms/inventory"
          exact
          component={Inventory}
          isPrivate
          isInside
        />
        <Route path="/wms/box" exact component={Box} isPrivate isInside />
        <Route path="/wms/pallet" exact component={Pallet} isPrivate isInside />
        <Route
          path="/wms/structure"
          exact
          component={Structure}
          isPrivate
          isInside
        />
        <Route
          path="/wms/boarding"
          exact
          component={Boarding}
          isPrivate
          isInside
        />
        <Route
          path="/wms/report-kardex"
          exact
          component={ReportKardex}
          isPrivate
          isInside
        />
        <Route
          path="/wms/components"
          exact
          component={Components}
          isPrivate
          isInside
        />
        <Route
          path="/wms/composition"
          exact
          component={Composition}
          isPrivate
          isInside
        />
        <Route
          path="/wms/composition/create/:id"
          exact
          component={CreateComposition}
          isPrivate
          isInside
        />
        <Route
          path="/wms/invoicereport/"
          exact
          component={InvoiceDivergent}
          isPrivate
          isInside
        />
        <Route
          path="/wms/receivementReport/"
          exact
          component={ReportReceivement}
          isPrivate
          isInside
        />
        <Route
          path="/wms/pickingReport/"
          exact
          component={ReportSeparation}
          isPrivate
          isInside
        />
        <Route
          path="/wms/inventory-monitoring/"
          exact
          component={ReportStock}
          isPrivate
          isInside
        />
        <Route
          path="/wms/conferenceReport/"
          exact
          component={ReportConference}
          isPrivate
          isInside
        />
        <Route
          path="/wms/parameter/"
          exact
          component={Parameters}
          isPrivate
          isInside
        />
        <Route
          path="/wms/changelog/"
          exact
          component={ChangeLog}
          isPrivate
          isInside
        />
        <Route
          path="/wms/integration/"
          exact
          component={Integration}
          isPrivate
          isInside
        />
        <Route
          path="/wms/followAge/"
          exact
          component={FollowProductAge}
          isPrivate
          isInside
        />
        <Route
          path="/wms/followWave/"
          exact
          component={FollowWave}
          isPrivate
          isInside
        />
        <Route path="/401" component={Error401} />
        <Route path="*" component={Error400} />
      </Switch>
    </BrowserRouter>
  );
};

export default routes;
