export default {
  translations: {
    header: {
      aboutMenu: {
        support: 'Suporte',
        help: 'Ajuda',
        about: 'Sobre',
      },
    },
    screens: {
      receivement: {
        follow_up: 'Acompanhamento',
        receivement: 'Recebimento',
        requestType: 'Tipo Pedido',
        order: 'Pedido',
        invoice: 'Nota Fiscal',
        status: 'Status',
        series: 'Serie',
        product: 'Produto',
        supplier: 'Fornecedor',
        initialDate: 'Data Inicial',
        finalDate: 'Data Final',
        details: 'Detalhes',
        generateConference: 'Gerar Conferência',
        endDivergence: 'Finalizar com Divergência',
        confirmEndDivergence:
          'Você tem certeza que deseja finalizar com divergência?',
        orderFinisherEnd: 'Pedido finalizado com divergência',
        progress: 'Progresso',
        actions: 'Ações',
        dateApproved: 'Data Apro',
        amountSku: 'Qtde. SKU',
        amount: 'Qtde',
        dateReceivement: 'Data Rec',
        dateEnd: 'Fim Rec',
        alertSeach:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo pedido e nota fiscal',
        successFilter: 'resultados encontrados',
        resultsNotFound: 'Resultados não encontrados',
        document: 'Documento',
        receiptDate: 'Data de recebimento',
        endDate: 'Data de fim',
        enterProduct: 'Digite o produto',
        enterBox: 'Digite a caixa',
        enterPallet: 'Digite o palete',
        dateChecked: 'Data conferida',
        size: 'Tam.',
        quantity: 'Qtde. NF',
        divergent: 'Divergência',
        dateReceipt: 'Data Rec.',
        successfullyReversed: 'estornado com sucesso',
        reversedSuccessfully: 'Recebimento Estornado com sucesso',
        isReverse: 'Deseja estornar o recebimento',
      },
      status: {
        canceled: 'Cancelado',
        divergent: 'Divergente',
        inProgress: 'Em Andamento',
        finished: 'Finalizado',
        completedDivergent: 'Finalizado divergente',
        integrated: 'Integrado OK',
        integratedDivergent: 'Integrado Divergente',
        invoiceDivergent: 'NF Divergente',
        pending: 'Pendente',
      },
      cardsFilters: {
        results: 'Resultados',
        finished: 'Finalizado',
        completedDivergent: 'Finalizado Divergent',
        inProgress: 'Em Andamento',
        divergent: 'Divergente',
        pending: 'Pendente',
        totalProgress: 'Progresso Total',
        create: 'Criado',
        planned: 'Planejado',
        progress: 'Andamento',
        resupply: 'Ressuprimento',
        completedShipment: 'Embarque Finalizado',
        finishedDivergence: 'Finalizado C/ Divergência',
        insufficientStock: 'Estoque Insuficiente',
        awaitingIntegration: 'Aguardando Integração',
        checkedQuantity: 'Quantidade Conferida',
        pickingQuantity: 'Quantidade Picking',
        totalAmount: 'Quantidade Total',
        startDate: 'Hora Início Separação',
        startTime: 'Hora Início Separação',
        endDate: 'Data Fim do Embarque',
        endTime: 'Hora Fim do Embarque',
        awaitingBilling: 'Aguardando Faturamento',
        awaitingShipment: 'Aguardando Expedição',
        awaitingConference: 'Aguardando Conferência',
        shipmentFinished: 'Expedição Finalizada',
        awaitingRelease: 'Aguardando Liberação',
        divergentQuantity: 'Quantidade Divergente',
        amountReceived: 'Quantidade Recebida',
      },
      structureAdresses: {
        type: 'Tipo',
        description: 'Descrição',
        isActive: 'Está Ativio?',
        noFound: 'Nenhum endereço encontrado',
        adreessesFound: 'endereços encontrados',
        structureEditSuccess: 'Estrutura editada com sucesso',
        successDeleteRecordes: 'Registro deletado com sucesso',
        notFoundRecordes: 'Nenhum registro foi encontrado.',
        wasFound: 'Foi encontrado',
        records: 'registro(s)',
        someError: 'Algo deu errado com a requisição',
        structureCreate: 'Estrutura criada com sucesso!',
        structure: 'Estrutura',
        titleScreen: 'Criar Estrutura',
        newStrucutureBtn: 'Nova Estrutura',
        create: 'Criar',
        createStructureTitle: 'Criar uma estrutura',
        edit: 'Editar',
        successfullyDeleted: 'Deletado com sucesso!',
        isDeleted: 'Deseja deletar ?',
        status: 'Status',
        actions: 'Ações',
        delete: 'Excluir',
        statusTrue: 'Ativo',
        statusFalse: 'Inativo',
      },
      address: {
        deposit: 'Deposito',
        type: 'Tipo',
        status: 'Status',
        adresses: 'Endereço',
        toWalk: 'Andar',
        road: 'Rua',
        modules: 'Modulos',
        level: 'Nivel',
        position: 'Posição',
        resulpply: 'Ressuprimento',
        capacity: 'Capacidade',
        resu: 'Ressu.',
        actions: 'Ações',
        delete: 'Excluir',
        edit: 'Editar',
        editStatus: 'Editar Status',
        newAddress: 'Novo Endereço',
        storage: 'Armazenagem',
        notFound: 'Não há registros de enderecos',
        foundResults: 'resultado(s) encontrados',
        error: 'Erro',
        branch: 'Filial',
        shippingCompany: 'Transportadora',
        branchsNotName: 'Não há filiais com esse nome',
        editedSuccessfully: 'Editado com sucesso!',
        saveButtonTitle: 'Salvar',
        notLeave: 'Não deixe o campo de status vazio!',
        updateStatus: 'Status atualizados com sucesso!',
        deleteSuccess: 'Deletado com sucesso',
        isDelete: 'Você tem certeza que deseja apagar o endereço',
        deleteAddress: 'Endereço excluído',
        createAddressTitle: 'Criar Endereço',
        createAddressSuccess: 'Criado com sucesso!',
        addressExist: 'Endereço Existente!',
        importFile: 'Importar Arquivo',
        importSuccess: 'Arquivo importado com sucesso!',
        uploadFile: 'Subir Arquivo',
        choiceFile: 'Escolha o arquivo para fazer upload.',
      },
      logAddress: {
        product: 'Produto',
        address: 'Endereço',
        initialDate: 'Data Incial',
        finalDate: 'Data Final',
        box: 'Caixa',
        pallet: 'Palete',
        type: 'Tipo',
        noRecordes: 'Não foi encontrado nenhum registro!',
        logStorage: 'Log Armazenagem',
        quantityPalete: 'Quantidade Palete',
        quantityBox: 'Quantidade Caixa',
        quantity: 'Quantidade',
        manufacturer: 'Fabricante',
        descriptionProduct: 'Descrição do Produto',
        dateStorage: 'Data Armazenagem',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo produto ou endereço',
      },
      orderFollowUp: {
        title: 'Pedido',
        followUp: 'Acompanhamento',
        orderType: 'Tipo Pedido',
        client: 'Cliente',
        period: 'Periodo',
        clientBranch: 'Cliente/Filial',
        receive: 'Recebido',
        planning: 'Planejamento',
        finished: 'Finalizado',
        endShipment: 'Fim Embarque',
        resultsNotFound: 'Resultados não encontrados.',
        recordsFound: 'Foi encontrado',
        clientAndBranch: 'Cliente/Filial',
        quantity: 'Quantidade',
        received: 'Recebido',
        check: 'Conferir',
        color: 'Cor',
        orderProgress: 'Progresso Pedido',
        branchsNotName: 'Não há filiais com esse nome',
        printing: 'Imprimindo',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo pedido',
      },
      reverseOrder: {
        errorTheRequest: 'Erro: O pedido',
        thisStatusCreate: 'já esta como o status criado!',
        orderStatus: 'Status do pedido',
        changedSuccessfully: 'alterado com sucesso!',
        noRecordesFound: 'Nenhum registro foi encontrado.',
        reverse: 'Estornar',
        wave: 'Onda',
        editStatusTitle: 'Editar Status - Pedido:',
        dateOrder: 'Data Pedido',
        quantityPieces: 'Quantidade Peças',
        newWave: 'Nova Onda',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo pedido ou onda!',
      },
      createWave: {
        included: 'incluido',
        onWave: 'na onda.',
        notIncluded: 'não incluido',
        branchsNotName: 'Não há filiais com esse nome',
        picking: 'Picking',
        createWave: 'Criar onda',
        taxUnit: 'UF',
        customerBranch: 'Cliente/Filial',
        quantityPieces: 'Qtde Peças',
        newWave: 'Nova Onda',
        selectedOrder: 'pedido(s) selecionado(s)',
        priority: 'Prioridade',
        splitVolume: 'Dividir Volume?',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo pedido.',
      },
      followPicking: {
        branchsNotName: 'Não há filiais com esse nome',
        picking: 'Picking',
        editUser: 'Editar Usuário',
        changeUser: 'Alterar Usuário',
        editUserSuccess: 'Usuário editado com sucesso',
        followPicking: 'Acompanhamento Conferência',
        separationFollowUp: 'Acompanhamento Separação',
        priority: 'Prioridade',
        customerBranch: 'Cliente/Filial',
        quantityPieces: 'Qtde. Peças',
        quantityPicking: 'Qtde. Picking',
        quantityChecked: 'Qtde. Conferida',
        dateOrder: 'Data Pedido',
        dateFinal: 'Data Final',
        document: 'Documento',
        startDate: 'Data de incío:',
        finalDate: 'Data de fim:',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pela onda e pedido.',
      },
      pickingConference: {
        product: 'Produto:',
        isResult: 'conferido com sucesso. Pedido:',
        picking: 'Picking',
        conference: 'Conferência',
        conferenceDetails: 'Detalle de Conferência',
        finalize: 'Finalizar!',
        conferenceFinished: 'Conferência Finalizada!',
        boxAlreadyAddressed: 'Caixa já endereçada!',
        boxNotExist: 'Caixa não existe!',
        boxNotBelong: 'Caixa não pertence ao pedido!',
        invalidBarcode: 'Código de Barras inválido',
        validatedSucces: 'Foi validado com sucesso!',
        quantityExceeded: 'Quantidade excedida!',
        insertBox: 'Favor inserir uma caixa para realizar a coleta!',
        endConference: 'Você tem certeza que deseja finalizar a conferência?',
        barCode: 'Cód. Barra',
        code: 'Código de Barras',
        box: 'Caixa',
        check: 'Conferir',
        conferred: 'Conferido',
        qty: 'Qtde.',
        boxType: 'Tipo de caixa',
        bathModalTitle: 'Lote',
        selectBath: 'Selecione o Lote',
        validity: 'Validade',
        checkedProduct: 'Produto Conferido',
      },
      transferenceFollowUp: {
        transfer: 'Transferência',
        origin: 'Origem',
        destiny: 'Destino',
        unit: 'Unidade',
        type: 'Tipo',
        transferDate: 'Data de Transferência',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre por unidade.',
      },
      expeditionFollowUp: {
        expedition: 'Expediçao',
        query: 'Consulta',
        state: 'Estado',
        dispatchDate: 'Data Expedição',
        customerBranch: 'Cliente/Filial',
        dispatchUser: 'Usuário Expedição',
        codeOrder: 'Cod. Pedido',
        codeExpedition: 'Cod. Expedição',
        validProduct: 'Produto valido',
        startDate: 'Data Inicio Expedição',
        endDate: 'Data Fim Expedição',
        branchsNotName: 'Não há filiais com esse nome',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo pedido.',
      },
      boarding: {
        boarding: 'Embarque',
        packingList: 'Romaneio',
        customerBranch: 'Cliente/Filial',
        invoiceQuantity: 'Qtde. NF',
        volumeQuantity: 'Qtde. Volume',
        exportButton: 'Exportar Romaneio(s) em PDF',
        requestCode: 'Código do pedido',
        orderType: 'Tipo Pedido',
        packingDetails: 'Detalhes Romaneio',
        recordFound: 'registro(s) encontrados',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo romaneio.',
      },
      manualOrder: {
        manualOrder: 'Cadastro Manual',
        fileUpload: 'Upload de arquivo',
        register: 'Cadastro',
        requireFilds: 'Preencha os campos necessários',
        receipt: 'Recebimento:',
        successfullyCreated: 'criado com sucesso!',
        order: 'Pedido:',
        created: 'criado!',
        operation: 'Operação',
        chooseOperation: 'Escolha a operação',
        prohibited: 'Entrada',
        exit: 'Saída',
        chooseType: 'Escolha um tipo',
        enterInvoice: 'Digite a nota fiscal',
        series: 'Serie',
        date: 'Data',
        branchCustomer: 'Código cliente ERP / Filial',
        carrierCode: 'Código da Transportadora',
        supplier: 'Fornecedor',
        chooseSupplier: 'Escolha o fornecedor',
        items: 'Itens',
        deletedItems: 'Items deletados',
        deletedItem: 'Item deletado',
        withSuccess: 'com êxito',
        ordem: 'Ordem',
        delete: 'Deletar',
        validData: 'Insira dados válidos',
        addedSuccessfully: 'adicionado com sucesso!',
        nameAndCode: 'Não há produtos com esse nome/código',
        chooseProduct: 'Escolha um produto',
        enterAmount: 'Digite uma quantidade',
        toAdd: 'Adicionar',
        chooseUpload: 'Escolha o arquivo para fazer upload.',
        liftFile: 'Subir Arquivo',
        chooseRegistration: 'Escolha a forma de cadastro',
      },
      parameters: {
        parameters: 'Parâmetro',
        administration: 'Administração',
        successfullyEdited: 'Estrutura editada com sucesso!',
        enterParemeter: 'Por favor digite o parâmetro',
        parametersDescription: 'Por favor digite a descrição do parâmetro',
        value: 'Valor',
      },
      registerProduct: {
        searchProduct: 'Pesquise algum produto!',
        thereProducts: 'Não há produtos',
        productsFound: 'produto(s) encontrados',
        register: 'Cadastro',
        productCode: 'Código do Produto',
        marked: 'Marcado',
        unchecked: 'Desmarcado',
        productEdited: 'Produto Editado sucesso',
        active: 'Ativo',
        level: 'Nível',
        height: 'Altura',
        width: 'Largura',
        length: 'Comprimento',
        weight: 'Peso',
        component: 'Componente',
        brandCode: 'Código Marca',
        receipt: 'Recebimento',
        receiptInDays: 'Recebimento (em dias)',
        exit: 'Saída',
        parameters: 'Parâmetros',
        marketingStructure: 'Estrutura Mercadológica',
        departureInDays: 'Saída (em dias)',
        batchControl: 'Controla Lote',
        controlsValidity: 'Controla Validade',
        controlsReceipt: 'Controla Lote Recebimento',
        receiptValidity: 'Controla Validade Recebimento',
        constrolStorage: 'Controla Lote Armazenagem',
        storageValidity: 'Controla Validade Armazenagem',
        constrolSeparation: 'Controla Lote Separacao',
        separationValidity: 'Controla Validade Separacao',
        checkControl: 'Controla Lote Conferencia',
        validityCheck: 'Controla Validade Conferencia',
        notFieldsEmpty: 'Não deixe campos Vazios',
        colorOrDescription: 'Cor ou Descrição',
        colorDesc: 'Cor/Desc',
        delete: 'Deletar',
      },
      produtivityConference: {
        produtivity: 'Produtividade',
        conference: 'Conference',
        name: 'Nome',
        summaryTable: 'Tabela Sintética',
        analyticalTable: 'Tabela Analítica',
        totalHours: 'Total horas',
        ordersTime: 'Pedidos hora',
        amountOrder: 'Qtde. Pedido',
        addressesTime: 'Qtde. Endereços Hora',
        amountAddresses: 'Qtde. Endereços',
        productTime: 'Produto Hora',
        amountProduct: 'Qtde. Produto',
        researchDate: 'Data Pesquisa',
        fisrtMessage: 'resultado(s) foram encontrados no analítico e',
        lastMessage: 'resultado(s) encontrados no sintético',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo nome.',
        avgHours: 'Média Horas Ini.',
        endAvgHours: 'Média Horas Fim',
        totalHourOrders: 'Pedidos Hora Total',
        quantityHours: 'Qtde. Pedidos Hora T.',
        quantityAddress: 'Qtde. End. Hora T.',
        totalAddress: 'Qtde. Endereços T.',
        productHours: 'Produto Hora Total',
        quantityHoursTotal: 'Qtde. Produto Total',
      },
      produtivityStorage: {
        notFieldEmpty: 'Não deixe esse campo vazio',
        choiceDate: 'Escolha uma data inicial e final!',
      },
      reportsStock: {
        boarding: 'Embrarque',
        brand: 'Marca',
        address: 'Endereço',
        descriptionProduct: 'Descrição Produto',
        codeRequest: 'Codigo do Pedido',
        reserved: 'Reservado',
        available: 'Disponível',
        inbound: 'Inbound',
        outBound: 'OutBound',
      },
      invoiceDivergent: {
        reports: 'Relatorios',
        orderDivergent: 'Pedido Divergente',
        series: 'Serie',
        date: 'Data',
        invoiceQuantity: 'Qtde. Nota Fiscal',
        orderQuantity: 'Qtde. Pedido',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo pedido.',
      },
      ressupply: {
        ressupply: 'Ressuprimento',
        origin: 'Origem',
        destiny: 'Destino',
        priority: 'Prioridade',
        reports: 'Relatorios',
      },
      insufficient: {
        insufficient: 'Insuficiente',
        reports: 'Relatorios',
        priority: 'Prioridade',
      },
      separation: {
        reports: 'Relatorios',
        separation: 'Separação',
        quantityPickink: 'Qtde. Picking',
        quantityOrder: 'Qtde. Pedido',
        series: 'Serie',
        nameClient: 'Nome Cliente',
        codeClient: 'Cod. Cliente',
        codShippingCompany: 'Cod. Transportadora',
        descriptionAddress: 'Desc. Endereço',
        billingIssue: 'Emissão Faturamento',
        billingDate: 'Data Faturamento',
        homeSeparation: 'Inicio Separação',
        endSeparation: 'Fim Separação',
      },
      conference: {
        reports: 'Relatorios',
        conference: 'Conferência',
        quantityConference: 'Qtde. Conferida',
        quantityOrder: 'Qtde. Pedido',
        series: 'Serie',
        nameClient: 'Nome Cliente',
        integrationDate: 'Data Integração',
        homeConference: 'Inicio Conferência',
        endConference: 'Fim Conferência',
        finalize: 'Finalizar',
        station: 'Estação',
        selectStation: 'Seleciona a Estação',
        errorMessage:
          'Ops! Verifique se todos os Pedido e Estação estão preenchidos',
      },
      reportReceipt: {
        receipt: 'Recebimento',
        reports: 'Relatorios',
        series: 'Serie',
        integrationDate: 'Data Integração',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo pedido ou nota fiscal.',
      },
      dashboard: {
        awaitingBilling: 'Aguardando Faturamento',
        awaitingIntegration: 'Aguardando Integração',
        conference: 'Conferência',
        ddressesUsed: 'Endereços utilizados',
        stock: 'Estoque',
        expedition: 'Expedição',
        insufficient: 'Insuficiente',
        requests: 'Pedidos',
        pickingProgress: 'Picking Andamento',
        divergentPicking: 'Picking Divergente',
        plannedPicking: 'Picking Planejado',
        resupply: 'Ressuprimento',
      },
      reportStock: {
        stored: 'Armazenado',
        shipped: 'Embarcado',
        invoiced: 'Faturado',
      },
      commom: {
        storage: 'Armazenagem',
        picking: 'Picking',
        detail: 'Detalhe',
        details: 'Detalhes',
        actions: 'Ações',
        user: 'Usuário',
        order: 'Pedido',
        status: 'Status',
        initialDate: 'Data Incial',
        startTime: 'Hora de Início',
        finalDate: 'Data Final',
        endTime: 'Hora do Fim',
        totalTime: 'Tempo Total',
        shippingCompany: 'Transportadora',
        branch: 'Filial',
        progress: 'Progresso',
        records: 'registro(s)',
        product: 'Produto',
        descriptionProduct: 'Descrição do Produto',
        box: 'Caixa',
        pallet: 'Palete',
        grid: 'Grade',
        size: 'Tamanho',
        invoice: 'Nota Fiscal',
        recordsFound: 'Foi encontrado',
        client: 'Cliente',
        edit: 'Editar',
        saveButtonTitle: 'Salvar',
        orderType: 'Tipo Pedido',
        resultsNotFound: 'Resultados não encontrados.',
        period: 'Periodo',
        orderDate: 'Data Pedido',
        yes: 'Sim',
        no: 'Não',
        create: 'Criar',
        followUp: 'Acompanhamento',
        wave: 'Onda',
        color: 'Cor',
        quantity: 'Quantidade',
        toClean: 'Limpar',
        search: 'Pesquisar',
        finalize: 'Finalizar',
        code: 'Código',
        successFilter: 'resultado(s) encontrados',
        description: 'Descrição',
        error: 'Erro',
        branchsNotName: 'Não há filiais com esse nome!',
        toAdd: 'Adicionar',
        deposit: 'Deposito',
        level: 'Nivel',
        stock: 'Estoque',
        reports: 'Relatorios',
        dashboard: 'Dashboard',
      },
      integration: {
        title: 'Monitoramento',
        subTitle: 'Processos',
        process: 'Processo',
        last_sync_at: 'Última Execução',
        last_sync_status: 'Última Execução Sucesso',
        next_sync_at: 'Próxima Execução',
        running: 'Executando',
        stopped: 'Parado',
        error: 'Erro',
      },
      followAge: {
        daysInStock: 'Dias em estoque',
        tenDays: '10 Dias',
        twentyDays: '20 Dias',
        thirtyDays: '30 Dias',
      },
      followWave: {
        followWave: 'Acompanhamento de onda',
        amountOrder: 'Qtde. Pedidos',
        amountProduct: 'Qtde. Produtos',
        waveDate: 'Data Onda',
      },
    },
    components: {
      search: {
        title: 'Pesquisar',
        clear: 'Limpar',
        advancedFilters: 'Filtros Avançados',
        closeAdvancedFilters: 'Menos Filtros',
        upload: 'Upload de Arquivos',
        loading: 'Carregando',
        filter: 'Filtros',
      },
      table: {
        export: 'Exportar',
        entries: 'Registros',
        to: 'a',
        noResults: 'Não foi encontrado nenhum registro',
      },
      user: {
        greetings: 'Olá',
      },
      pageMode: {
        close: 'Fechar',
        back: 'Voltar',
        printOut: 'Imprimir',
      },
      confirmationModal: {
        close: 'Fechar',
        confirm: 'Confirmar',
        cancel: 'Cancelar',
      },
      userPopper: {
        profile: 'Perfil',
        config: 'Configurações',
      },
    },
  },
};
