export default {
  translations: {
    header: {
      aboutMenu: {
        support: 'Support',
        help: 'Help',
        about: 'About',
      },
    },
    screens: {
      receivement: {
        follow_up: 'Follow Up',
        receivement: 'Receivement',
        requestType: 'Request Type',
        order: 'Order',
        invoice: 'Invoice',
        status: 'Status',
        series: 'Series',
        product: 'Product',
        supplier: 'Supplier',
        initialDate: 'Initial Date',
        finalDate: 'Final Date',
        details: 'Details',
        generateConference: 'Generate Conference',
        endDivergence: 'End with Divergence',
        confirmEndDivergence: 'Are you sure you want to end with divergence?',
        orderFinisherEnd: 'Order completed with discrepancy',
        progress: 'Progress',
        actions: 'Actions',
        dateApproved: 'Date Appro',
        amountSku: 'QTY. SKU',
        amount: 'QTY',
        dateReceivement: 'Date Rec',
        dateEnd: 'End Rec',
        alertSeach:
          'Please enter dates with a maximum difference of 30 days or filter by order and invoice',
        successFilter: 'results found',
        resultsNotFound: 'Results not found',
        document: 'Document',
        receiptDate: 'Receipt date',
        endDate: 'End date',
        enterProduct: 'Enter the product',
        enterBox: 'Enter the box',
        enterPallet: 'Enter the pallet',
        dateChecked: 'Date checked',
        size: 'Size',
        quantity: 'Qty. Invoice',
        divergent: 'Divergence',
        dateReceipt: 'Date Rec.',
        successfullyReversed: 'successfully reversed',
        reversedSuccessfully: 'Receipt Reversed Successfully',
        isReverse: 'Do you want to reverse the receipt',
      },
      status: {
        canceled: 'Canceled',
        divergent: 'Divergent',
        inProgress: 'In Progress',
        finished: 'Finished',
        completedDivergent: 'Completed Divergent',
        integrated: 'Integrated OK',
        integratedDivergent: 'Integrated Divergent',
        invoiceDivergent: 'Invoice Divergent',
        pending: 'Pending',
      },
      cardsFilters: {
        results: 'Results',
        finished: 'Finished',
        completedDivergent: 'Completed Divergent',
        inProgress: 'In Progress',
        divergent: 'Divergent',
        pending: 'Pending',
        totalProgress: 'Total Progress',
        create: 'Create',
        planned: 'Planned',
        progress: 'Progress',
        resupply: 'Resupply',
        completedShipment: 'Completed Shipment',
        finishedDivergence: 'Finished W/ Divergence',
        insufficientStock: 'Insufficient Stock',
        awaitingIntegration: 'Awaiting Integration',
        checkedQuantity: 'Checked Quantity',
        pickingQuantity: 'Picking Quantity',
        totalAmount: 'Total amount',
        startDate: 'Separation Start Date',
        startTime: 'Time Start Separation',
        endDate: 'Shipment End Date',
        endTime: 'End Time Boarding',
        awaitingBilling: 'Awaiting Billing',
        awaitingShipment: 'Awaiting Shipment',
        awaitingConference: 'Awaiting Conference',
        shipmentFinished: 'Shipment Finished',
        awaitingRelease: 'Awaiting Release',
        divergentQuantity: 'Divergent Quantity',
        amountReceived: 'Amount received',
      },
      structureAdresses: {
        type: 'Type',
        description: 'Description',
        isActive: 'Is Active?',
        noFound: 'No address found',
        adreessesFound: 'addresses found',
        structureEditSuccesses: 'Structure successfully edited',
        successDeleteRecordes: 'Record deleted successfully',
        notFoundRecordes: 'No records found.',
        wasFound: 'Was found',
        records: 'records',
        someError: 'Something went wrong with the request',
        structureCreate: '¡Estructura creada con éxito!',
        structure: 'Structure',
        titleScreen: 'Create Structure',
        newStrucutureBtn: 'New Structure',
        create: 'Create',
        createStructureTitle: 'Create a structure',
        edit: 'To Edit',
        successfullyDeleted: 'Successfully Deleted!',
        isDeleted: 'Do you want to delete?',
        status: 'Status',
        actions: 'Actions',
        delete: 'Delete',
        statusTrue: 'Active',
        statusFalse: 'Inactive',
      },
      address: {
        deposit: 'Deposit',
        type: 'Type',
        status: 'Status',
        adresses: 'Address',
        toWalk: 'To walk',
        road: 'Road',
        modules: 'Modules',
        level: 'Level',
        position: 'Position',
        resulpply: 'Resupply',
        capacity: 'Capacity',
        resu: 'Resu.',
        actions: 'Actions',
        delete: 'Delete',
        edit: 'To Edit',
        editStatus: 'Edit Status',
        newAddress: 'New Address',
        storage: 'Storage',
        notFound: 'There are no address records',
        foundResults: 'results found',
        error: 'Error',
        branch: 'Branch',
        shippingCompany: 'Shipping company',
        branchsNotName: 'There are no branches with that name.',
        editedSuccessfully: 'Edited successfully!',
        saveButtonTitle: 'Save',
        notLeave: 'Do not leave the status field empty!',
        updateStatus: 'Status updated successfully!',
        deleteSuccess: 'Successfully deleted',
        isDelete: 'Are you sure you want to delete the address',
        deleteAddress: 'Deleted address',
        createAddressTitle: 'Create Address',
        createAddressSuccess: 'Successfully created!',
        addressExist: 'Existing Address!',
        importFile: 'Import File',
        importSuccess: 'File imported successfully!',
        uploadFile: 'Upload File',
        choiceFile: 'Choose the file to upload.',
      },
      logAddress: {
        product: 'Product',
        address: 'Address',
        initialDate: 'Initail Date',
        finalDate: 'Final Date',
        box: 'Box',
        pallet: 'Pallet',
        type: 'Type',
        noRecordes: 'No Records Found!',
        logStorage: 'Log Storage',
        quantityPalete: 'Quantity Pallet',
        quantityBox: 'Quantity Box',
        quantity: 'Quantity',
        manufacturer: 'Manufacturer',
        descriptionProduct: 'Product Description',
        dateStorage: 'Storage Date',
        alertSearch:
          'Please enter dates with a maximum difference of 30 days or filter by product or address',
      },
      orderFollowUp: {
        title: 'Order',
        followUp: 'Follow-up',
        orderType: 'Order Type',
        client: 'Client',
        period: 'Period',
        clientBranch: 'Client/Branch',
        receive: 'Received',
        planning: 'Planning',
        finished: 'Finished',
        endShipment: 'End Shipment',
        resultsNotFound: 'Results not found.',
        recordsFound: 'Was found',
        clientAndBranch: 'Client/Branch',
        quantity: 'Amount',
        received: 'Received',
        check: 'Check',
        color: 'Color',
        orderProgress: 'Order Progress',
        branchsNotName: 'There are no branches with that name.',
        printing: 'Printing',
        alertSearch:
          'Please enter dates with a maximum difference of 30 days or filter by order',
      },
      reverseOrder: {
        errorTheRequest: 'Error: The request',
        thisStatusCreate: 'this is already as the status created!',
        orderStatus: 'Order status',
        changedSuccessfully: 'changed successfully!',
        noRecordesFound: 'No records found.',
        reverse: 'Reverse',
        wave: 'Wave',
        editStatusTitle: 'Edit Status - Order:',
        dateOrder: 'Order Date',
        quantityPieces: 'Qty Pieces',
        newWave: 'New Wave',
        alertSearch:
          'Please enter dates with a maximum difference of 30 days or filter by order or wave!',
      },
      createWave: {
        included: 'included',
        onWave: 'on the wave.',
        notIncluded: 'not included',
        branchsNotName: 'There are no branches with that name.',
        picking: 'Picking',
        createWave: 'Create wave',
        taxUnit: 'TU',
        customerBranch: 'Customer/Branch',
        quantityPieces: 'Qty Pieces',
        newWave: 'New Wave',
        selectedOrder: 'selected order(s)',
        priority: 'Priority',
        splitVolume: 'Split Volume?',
        alertSearch:
          'Please enter dates with a maximum difference of 30 days or filter by order.',
      },
      followPicking: {
        branchsNotName: 'There are no branches with that name.',
        picking: 'Picking',
        editUser: 'Edit User',
        changeUser: 'Change User',
        editUserSuccess: 'User successfully edited',
        followPicking: 'Conference Follow-up',
        separationFollowUp: 'Separation Follow-up',
        priority: 'Priority',
        customerBranch: 'Customer/Branch',
        quantityPieces: 'Qty. Pieces',
        quantityPicking: 'Qty. Picking',
        quantityChecked: 'Qty. Checked',
        dateOrder: 'Order Date',
        dateFinal: 'Final Date',
        document: 'Document',
        startDate: 'Start date:',
        finalDate: 'End date:',
        alertSearch:
          'Please enter dates with a maximum difference of 30 days or filter by wave and order.',
      },
      pickingConference: {
        product: 'Product:',
        isResult: 'successfully checked. Order:',
        picking: 'Picking',
        conference: 'Conference',
        conferenceDetails: 'Conference Details',
        finalize: 'Finalize!',
        conferenceFinished: 'Conference Finished!',
        boxAlreadyAddressed: 'Box already addressed!',
        boxNotExist: 'Box does not exist!',
        boxNotBelong: 'Box does not belong to the order!',
        invalidBarcode: 'Invalid Barcode',
        validatedSucces: 'It has been validated successfully!',
        quantityExceeded: 'Quantity exceeded!',
        insertBox: 'Please insert a box to collect!',
        endConference: 'Are you sure you want to end the conference?',
        barCode: 'Bar code',
        code: 'Bar code',
        box: 'Box',
        check: 'Check',
        conferred: 'Conferred',
        qty: 'Qty.',
        boxType: 'Box Type',
        bathModalTitle: 'Batch',
        selectBath: 'Select the Batch',
        validity: 'Validity',
        checkedProduct: 'Checked Product',
      },
      transferenceFollowUp: {
        transfer: 'Transfer',
        origin: 'Origin',
        destiny: 'Destiny',
        unit: 'Unit',
        type: 'Type',
        transferDate: 'Transfer Date',
        alertSearch:
          'Please enter dates with a maximum difference of 30 days or filter by unit.',
      },
      expeditionFollowUp: {
        expedition: 'Expedition',
        query: 'Query',
        state: 'State',
        dispatchDate: 'Dispatch Date',
        customerBranch: 'Customer/Branch',
        dispatchUser: 'Dispatch User',
        codeOrder: 'Cod. Order',
        codeExpedition: 'Cod. Expedition',
        validProduct: 'Valid Product',
        startDate: 'Dispatch Start Date',
        endDate: 'Dispatch End Date',
        branchsNotName: 'There are no branches with that name.',
        alertSearch:
          'Please enter dates with a maximum difference of 30 days or filter by order.',
      },
      boarding: {
        boarding: 'Boarding',
        packingList: 'Packing List',
        customerBranch: 'Customer/Branch',
        invoiceQuantity: 'Qty. Inv',
        volumeQuantity: 'Qtde. Volume',
        exportButton: 'Export packing list in PDF',
        requestCode: 'Request code',
        orderType: 'Order Type',
        packingDetails: 'Packing Details',
        recordFound: 'record(s) found',
        alertSearch:
          'Please enter dates with a maximum difference of 30 days or filter by packing list.',
      },
      manualOrder: {
        manualOrder: 'Manual Registration',
        fileUpload: 'File upload',
        register: 'Register',
        requireFilds: 'Fill in the required fields',
        receipt: 'Receipt:',
        successfullyCreated: 'successfully created!',
        order: 'Order:',
        created: 'created!',
        operation: 'Operation',
        chooseOperation: 'Choose the operation',
        prohibited: 'Prohibited',
        exit: 'Exit',
        chooseType: 'Choose a type',
        enterInvoice: 'Enter the invoice',
        series: 'Series',
        date: 'Date',
        branchCustomer: 'ERP / Branch customer code',
        carrierCode: 'Carrier Code',
        supplier: 'Supplier',
        chooseSupplier: 'Choose supplier',
        items: 'Items',
        deletedItems: 'Deleted items',
        deletedItem: 'Item deleted',
        withSuccess: 'with success',
        ordem: 'Order',
        delete: 'Delete',
        validData: 'Enter valid data',
        addedSuccessfully: 'added successfully!',
        nameAndCode: 'There are no products with this name/code',
        chooseProduct: 'Choose a product',
        enterAmount: 'Enter an amount',
        toAdd: 'To add',
        chooseUpload: 'Choose the file to upload.',
        liftFile: 'Upload File',
        chooseRegistration: 'Choose registration form',
      },
      parameters: {
        parameters: 'Parameter',
        administration: 'Administration',
        successfullyEdited: 'Structure successfully edited!',
        enterParemeter: 'Please enter the parameter',
        parametersDescription: 'Please enter parameter description',
        value: 'Value',
      },
      registerProduct: {
        searchProduct: 'Search for a product!',
        thereProducts: 'There are no products',
        productsFound: 'product(s) found',
        register: 'Register',
        productCode: 'Product code',
        marked: 'Marked',
        unchecked: 'Unchecked',
        productEdited: 'Product edited success',
        active: 'Active',
        level: 'Level',
        height: 'Height',
        width: 'Width',
        length: 'Length',
        weight: 'Weight',
        component: 'Component',
        brandCode: 'Branch Code',
        receipt: 'Receipt',
        receiptInDays: 'Receipt (in days)',
        exit: 'Exit',
        parameters: 'Parameters',
        marketingStructure: 'Marketing Structure',
        departureInDays: 'Departure (in days)',
        batchControl: 'Batch Control',
        controlsValidity: 'Controls Validity',
        controlsReceipt: 'Controls Batch Receipt',
        receiptValidity: 'Controls Receipt Validity',
        constrolStorage: 'Controls Storage Lot',
        storageValidity: 'Controls Storage Validity',
        constrolSeparation: 'Control Batch Separation',
        separationValidity: 'Controls Separation Validity',
        checkControl: 'Check Batch Control',
        validityCheck: 'Control Validity Check',
        notFieldsEmpty: 'Do not leave fields empty',
        colorOrDescription: 'Color or Description',
        colorDesc: 'Color/Desc',
        delete: 'Delete',
      },
      produtivityConference: {
        produtivity: 'Productivity',
        conference: 'Conference',
        name: 'Name',
        summaryTable: 'Summary Table',
        analyticalTable: 'Analytical Table',
        totalHours: 'Total hours',
        ordersTime: 'Orders time',
        amountOrder: 'Qty. Order',
        addressesTime: 'Qty. Addresses Time',
        amountAddresses: 'Qty. Addresses',
        productTime: 'Product Time',
        amountProduct: 'Qty. Product',
        researchDate: 'Research Date',
        fisrtMessage: 'result(s) were found in the analytical and',
        lastMessage: 'result(s) found in synthetic',
        alertSearch:
          'Please enter dates with a maximum difference of 30 days or filter by name.',
        avgHours: 'Average Hours Start.',
        endAvgHours: 'Average Hours End',
        totalHourOrders: 'Total Hour Orders',
        quantityHours: 'Qty. Hour Orders T.',
        quantityAddress: 'Qty. Addr. Hour T.',
        totalAddress: 'Qty. Addresses T.',
        productHours: 'Total Hour Product',
        quantityHoursTotal: 'Qtde. Total Product',
      },
      produtivityStorage: {
        notFieldEmpty: 'Do not leave this field empty',
        choiceDate: 'Choose a start and end date!',
      },
      reportsStock: {
        boarding: 'Boarding',
        brand: 'Brand',
        address: 'Address',
        descriptionProduct: 'Product Description',
        codeRequest: 'Request code',
        reserved: 'Reserved',
        available: 'Available',
        inbound: 'Inbound',
        outBound: 'OutBound',
      },
      invoiceDivergent: {
        reports: 'Reports',
        orderDivergent: 'Divergent Order',
        series: 'Series',
        date: 'Date',
        invoiceQuantity: 'Qty. Invoice',
        orderQuantity: 'Qty. Order',
        alertSearch:
          'Please enter dates with a maximum difference of 30 days or filter by order.',
      },
      ressupply: {
        ressupply: 'Ressupply',
        origin: 'Origin',
        destiny: 'Destiny',
        priority: 'Priority',
        reports: 'Reports',
      },
      insufficient: {
        insufficient: 'Insufficient',
        reports: 'Reports',
        priority: 'Priority',
      },
      separation: {
        reports: 'Reports',
        separation: 'Separation',
        quantityPickink: 'Qty. Picking',
        quantityOrder: 'Qty. Order',
        series: 'Series',
        nameClient: 'Customer Name',
        codeClient: 'Cod. Client',
        codShippingCompany: 'Cod. Shipping company',
        descriptionAddress: 'Desc. Address',
        billingIssue: 'Billing Issue',
        billingDate: 'Billing Date',
        homeSeparation: 'Home Separation',
        endSeparation: 'End Separation',
      },
      conference: {
        reports: 'Reports',
        conference: 'Conference',
        quantityConference: 'Qty. Conference',
        quantityOrder: 'Qty. Order',
        series: 'Series',
        nameClient: 'Customer Name',
        integrationDate: 'Integration Date',
        homeConference: 'Home Conference',
        endConference: 'End of Conference',
        finalize: 'Finalize',
        station: 'Station',
        selectStation: 'Select the Station',
        errorMessage: 'Oops! Make sure all Order and Station are filled',
      },
      reportReceipt: {
        receipt: 'Receipt',
        reports: 'Reports',
        series: 'Series',
        integrationDate: 'Integration Date',
        alertSearch:
          'Please enter dates with a maximum difference of 30 days or filter by order or invoice.',
      },
      dashboard: {
        awaitingBilling: 'Awaiting Billing',
        awaitingIntegration: 'Awaiting Integration',
        conference: 'Conference',
        ddressesUsed: 'Addresses used',
        stock: 'Stock',
        expedition: 'Expedition',
        insufficient: 'Insufficient',
        requests: 'Requests',
        pickingProgress: 'Picking Progress',
        divergentPicking: 'Divergent Picking',
        plannedPicking: 'Planned Picking',
        resupply: 'Resupply',
      },
      reportStock: {
        stored: 'Stored',
        shipped: 'Shipped',
        invoiced: 'Invoiced',
      },
      commom: {
        storage: 'Storage',
        detail: 'Detail',
        details: 'Details',
        actions: 'Actions',
        user: 'User',
        order: 'Order',
        status: 'Status',
        initialDate: 'Initail Date',
        startTime: 'Start time',
        finalDate: 'Final Date',
        endTime: 'End Time',
        totalTime: 'Total Time',
        shippingCompany: 'Shipping company',
        branch: 'Branch',
        progress: 'Progress',
        records: 'records',
        product: 'Product',
        descriptionProduct: 'Product Description',
        box: 'Box',
        pallet: 'Pallet',
        grid: 'Grid',
        size: 'Size',
        invoice: 'Invoice',
        recordsFound: 'Was found',
        client: 'Client',
        edit: 'To Edit',
        saveButtonTitle: 'Save',
        orderType: 'Order Type',
        resultsNotFound: 'Results not found',
        period: 'Period',
        orderDate: 'Order Date',
        yes: 'Yes',
        no: 'No',
        create: 'Create',
        followUp: 'Follow Up',
        wave: 'Wave',
        color: 'Color',
        quantity: 'Quantity',
        toClean: 'To clean',
        search: 'Search',
        finalize: 'Finalize',
        code: 'Code',
        successFilter: 'results found',
        description: 'Description',
        error: 'Error',
        branchsNotName: 'There are no branches with that name!',
        toAdd: 'To add',
        deposit: 'Deposit',
        level: 'Level',
        stock: 'Stock',
        reports: 'Reports',
        dashboard: 'Dashboard',
      },
      integration: {
        title: 'Integration',
        subTitle: 'Processess',
        process: 'Process',
        last_sync_at: 'Last Sync At',
        last_sync_status: 'Last Sync Success',
        next_sync_at: 'Next Sync At',
        running: 'Running',
        stopped: 'Stopped',
        error: 'Error',
      },
      followAge: {
        daysInStock: 'Days in stock',
        tenDays: '10 Days',
        twentyDays: '20 Days',
        thirtyDays: '30 Days',
      },
      followWave: {
        followWave: 'Follow Wave',
        amountOrder: 'Qty. Orders',
        amountProduct: 'Qty. Products',
        waveDate: 'Wave Date',
      },
    },
    components: {
      search: {
        title: 'Search',
        clear: 'Clear',
        advancedFilters: 'Advanced Filters',
        closeAdvancedFilters: 'Less Filters',
        upload: 'Upload Files',
        loading: 'Loading',
        filter: 'Filters',
      },
      table: {
        export: 'Export',
        entries: 'Entries',
        to: 'to',
        noResults: 'No results found',
      },
      user: {
        greetings: 'Hello',
      },
      pageMode: {
        close: 'Close',
        back: 'Back',
        printOut: 'Print out',
      },
      confirmationModal: {
        close: 'Close',
        confirm: 'Confirm',
        cancel: 'Cancel',
      },
      userPopper: {
        profile: 'Profile',
        config: 'Settings',
      },
    },
  },
};
