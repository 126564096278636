import Cookies from 'universal-cookie/es6/Cookies';

import { LooseAutoComplete } from 'helpers/types';

const cookies = new Cookies();

const cookiesArray = [
  '@pdamodules::id',
  '@pdamodules::token',
  '@pdamodules::login',
  '@pdamodules::active',
  '@pdamodules::user',
  '@pdamodules::refreshtoken',
  '@pdamodules::codigoCliente',
  '@pdamodules::CodigoSistema',
  '@pdamodules::descricaoCliente',
] as const;

type CookieName = (typeof cookiesArray)[number];

type PDACookies = LooseAutoComplete<CookieName>;

export const getCookie = (cookieName: PDACookies) => {
  const searchCookie = cookies.get(cookieName.toString());

  return searchCookie;
};

export const setCookie = <T>(cookie: PDACookies, value: T, maxAge?: number) => {
  return cookies.set(cookie.toString(), value, { maxAge });
};

export const removeCookie = (cookieName: PDACookies): void => {
  return cookies.remove(cookieName.toString());
};

export const removeAllCookies = () =>
  cookiesArray.forEach(cookie => removeCookie(cookie));
