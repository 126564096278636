import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { useTema } from 'hooks/Theme';
import { IconButton } from '@material-ui/core';
import Brightness4OutlinedIcon from '@material-ui/icons/Brightness4Outlined';
import { Container } from './styles';

const Header: React.FC = () => {
  const { title, colors } = useTheme();
  const { toggleTheme } = useTema();

  return (
    <Container>
      <div className="content">
        {/* Logo PDA */}
        <div className="client">
          <svg
            width="122"
            height="34"
            viewBox="0 0 122 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 2H13C16 2.33333 22 4.8 22 12C22 19.2 16 21.6667 13 22H3C2.72386 22 2.5 22.2239 2.5 22.5V32"
              stroke={`${colors.logo}`}
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <path
              d="M38 2.5H29C28.7239 2.5 28.5 2.72386 28.5 3V31C28.5 31.2761 28.7239 31.5 29 31.5H38C42.5 31.3333 51.5 28.3 51.5 17.5C51.5 6.7 42.5 3 38 2.5Z"
              stroke={`${colors.logo}`}
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <path
              d="M81 27.2632L68.9385 2.88622C68.7567 2.5188 68.2343 2.51425 68.0461 2.87845L53.3769 31.2705C53.205 31.6033 53.4465 32 53.8211 32H68"
              stroke={`${colors.logo}`}
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <path
              d="M93 2C95.8499 2.44222 99.4095 3.18865 103 4.63644C111.588 8.09953 120.353 15.5755 120 32.5"
              stroke="#E12325"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <circle cx="91.5" cy="29.6" r="3" fill="#E12325" />
            <path
              d="M91 19.5C94.6667 19.8333 102 22.7 102 31.5"
              stroke="#E12325"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <path
              d="M92 10C98.4969 10.1705 111.391 14.8093 110.991 32"
              stroke="#E12325"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
          </svg>
          {/* {user && getImage(`${user?.data.nome}`)} */}
          {/* ${user?.cliente} */}
        </div>
        <div className="infos">
          <IconButton
            onClick={toggleTheme}
            aria-label="theme"
            className="switchTheme"
          >
            <Brightness4OutlinedIcon
              color={title === 'light' ? 'disabled' : 'primary'}
            />
          </IconButton>

          {/* SVG sair */}
        </div>
      </div>
    </Container>
  );
};

export default Header;
