import axios, { isCancel } from 'axios';
import { toast } from 'react-toastify';

import { getCookie, removeAllCookies, setCookie } from 'utils/cookies';
import config from '../config';

const login = getCookie('@pdamodules::login');
const refreshToken = getCookie('@pdamodules::refreshtoken');
const access_token = getCookie('@pdamodules::token');

const api = axios.create({
  baseURL: config.apiConfig.baseUrl,
  headers: { Authorization: `Bearer ${access_token?.replace(/"/g, '')}` },
});

api.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  },

  error => {
    if (isCancel(error)) return null;

    if (error.response.status === 401) {
      reAuthentication();
    }

    if (error.response.status !== 401) {
      toast.error('Ops! algo deu errado');
    }

    return Promise.reject(error);
  }
);

function reAuthentication() {
  toast.warning('Reautenticando...');
  api
    .post(`${config.apiConfig.baseUrl}Autenticacao/Refresh-Token`, {
      login,
      refreshToken,
    })
    .then(response => {
      const { accessToken, refreshToken: newRefreshToken } = response.data;
      api.defaults.headers.Authorization = `Bearer ${accessToken}`;
      setCookie('@pdamodules::refreshtoken', newRefreshToken, 3600);
      setCookie('@pdamodules::token', accessToken, 2000);
      window.location.reload();
    })
    .catch(() => {
      removeAllCookies();
      sessionStorage.clear();
      window.location.replace(
        `${
          process.env.REACT_APP_ENV === 'dev'
            ? process.env.REACT_APP_LOGOUT_DEV_URL
            : process.env.REACT_APP_LOGOUT_PROD_URL
        }`
      );
    });
}

export default api;
