import styled, { keyframes } from 'styled-components';
import { Fade } from '@material-ui/core';

interface IconProps {
  titulo?: string;
  dashboard?: ItemProps[];
}

interface ItemProps {
  titulo: string;
  valor: string;
  icone: string;
  ordem?: number | string;
}

export const Container = styled.div<{
  withOutPadding?: boolean;
  isOrderList?: boolean;
}>`
  width: 100%;
  padding-top: ${props => !props.withOutPadding && '2rem'};
  padding-left: 2rem;
  padding-right: 2rem;
  box-shadow: none !important;
  /* SearchBox colors */

  & > * {
    max-width: 100%;
  }

  @media screen and (max-width: 500px) {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .cardHeader {
    display: flex;
    flex-direction: column;
    padding: 2rem 0 0 0;
    background: ${props =>
      props.theme.title === 'light' ? 'rgba(245, 248, 250, 0.4)' : '#2A2A3D'};
    border: 1px dashed
      ${props => (props.theme.title === 'light' ? '#e4e6ef' : '#191924')};
    border-radius: 6px;
    margin-bottom: 1rem;
    margin: 15px;

    .details {
      .detailsContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .detailsBottomRow {
        display: flex;
        gap: 45px;
        flex-wrap: wrap;
      }
      #firstRow {
        margin: 0;
        .detailsItem {
          margin-right: 25px;
          :first-of-type {
            p:last-of-type {
              color: #f64e60;
            }
          }
          :last-of-type {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            p:last-of-type {
              color: #f64e60;
              font-weight: 400;
            }
          }
        }
      }
      .detailsItemsContainer {
        display: flex;
        justify-content: space-between;
        margin: 12px 0 20px 0;
        flex-wrap: wrap;
        padding: 0 10px;

        .totalTimeResume {
          margin-right: 10px;
          .detailsItem {
            p:last-of-type {
              color: #46be7d;
              font-weight: 600;
            }
          }
        }

        .detailsItem {
          :last-of-type {
            display: flex;
            flex-direction: column;
          }
          p {
            :first-of-type {
              font-family: 'Poppins';
              font-weight: 400;
              font-size: 12px;
              color: ${props =>
                props.theme.title === 'light' ? '#7E8299' : '#EFF2F5'};
            }
            :last-of-type {
              font-family: 'Poppins';
              font-weight: 400;
              font-size: 18px;
              color: ${props =>
                props.theme.title === 'light' ? '#3F4254' : '#B5B5C3'};
              opacity: 0.7;
            }
          }
        }
      }
    }
  }

  .conferenceContainer {
    width: 100%;
    height: fit-content;
    background: ${props => props.theme.colors.dashboard_card_bg};
    border-radius: 12px;

    .conferenceHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 17px 10px 15px 20px;
      p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: ${props => props.theme.colors.container_title};
      }
    }
    .conferenceContentWrapper {
      width: 100%;
      padding: ${props => (props.isOrderList ? '10px 0 100px' : '10px')};
      display: grid;
      place-items: center;
      min-height: ${props => (props.isOrderList ? '200px' : '461px')};

      .ordersList {
        display: flex;
        justify-content: flex-end !important;
        top: 0;
        right: 0;
      }

      .conferenceContent {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        max-width: 500px;

        .conferenceButtons {
          display: flex;
          gap: 10px;
          margin-top: 50px;

          button {
            all: unset;
            cursor: pointer;

            display: flex;
            align-items: center;

            padding: 13px 16px;

            border-radius: 6px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
          }

          .submitButton {
            background-color: ${props =>
              props.theme.title === 'light' ? '#0095E8' : '#187DE4'};
            color: #fff;
            padding: 13px 16px;

            p {
              margin-left: 8px;
            }

            &:hover {
              transition: 0.7s;
              background-color: ${props =>
                props.theme.title === 'light' ? '#3fbaff' : '#0095E8'};
              color: #fff;
            }
          }

          .clearButton {
            background-color: ${props =>
              props.theme.colors.tagStatusColors
                .finalizado_divergente_or_bloqueado.bg};
            color: ${props =>
              props.theme.colors.tagStatusColors
                .finalizado_divergente_or_bloqueado.color};
            padding: 13px 16px;
            &:hover {
              transition: 0.7s;
              background-color: #f1416c;
              color: #fff5f8;
              path {
                fill: #fff5f8;
              }
            }

            p {
              margin-left: 8px;
            }

            img {
              padding-right: 7px;
            }
          }
        }

        input {
          height: 60px !important;
          width: 100%;
          max-width: 500px !important;
          font-size: 20px !important;

          &::placeholder {
            font-size: 20px;
            line-height: 45px;
            font-weight: 400;
            color: #b5b5c3;
          }
        }
        /* @media screen and (max-width: 660px) {
          padding: 10px !important;
        } */
      }
    }
  }

  .total {
    flex-grow: 1;
    width: 100%;
    height: auto;
    padding: 1.5rem;
    background: ${props => props.theme.colors.background};
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    color: ${props => props.theme.colors.title};
    box-shadow: 0px 7px 15px rgb(0 0 0 / 7%);
    background: ${props => props.theme.colors.total};

    p {
      font-size: 14px;
      white-space: nowrap;
      text-align: center;
      color: ${props => props.theme.colors.textTable};

      span {
        font-weight: 600;
        margin-left: 0.5rem;
        color: ${props => props.theme.colors.title};
      }
    }
  }

  .containerSearch {
    width: 100%;
    margin: 0;
    background: ${props => props.theme.colors.header_background};
    border-radius: 5px;
    box-shadow: none !important;

    .advancedContent {
      margin-left: 1rem;
    }

    .searchTitle {
      padding-left: 0.7rem;
      .returnClick {
        display: flex;
        align-items: center;
        h1 {
          margin-left: 1rem;
        }
      }

      h1 {
        color: ${props => props.theme.colors.title} !important;
      }
    }

    .dividerSearch {
      background: ${props => props.theme.colors.line} !important;
    }

    .labelInput {
      color: #b5b5c3;
      margin: 0 2px 5px 7px;
      font-weight: 400;
      font-size: 13px;
    }
    .containerFilter {
      display: flex;
      justify-content: center;
      .filter-Btn-Create {
        margin-left: 12px;
        @media (max-width: 640px) {
          font-size: 10px;
          margin-left: 16px;
        }
      }
      .filter-Btn-Create + .filter-Btn-Create {
        margin-left: 1rem;
      }
    }
    .MuiIconButton-label {
      justify-content: center;
    }
    .filter-row {
      position: relative;
      .filter-header {
        width: 100%;
        border-bottom: 0.5px solid ${props => props.theme.colors.line};
        padding: 1rem;
        display: flex;
        align-items: center;
        color: ${props => props.theme.colors.title};
        justify-content: space-between;
      }
      .filterButton {
        background-color: ${props =>
          props.theme.title === 'light' ? '#e8fff3' : '#1C3238'};
        p {
          color: ${props =>
            props.theme.title === 'light' ? '#46be7d' : '#04AA77'};
          font-family: 'Poppins';
          font-weight: 500;
          font-size: 12px;
        }
        path {
          fill: ${props =>
            props.theme.title === 'light' ? '#46be7d' : '#04AA77'};
        }
        &:hover {
          transition: 0.5s;
          background-color: #50cd89;
          p {
            color: #e8fff3;
          }
          path {
            fill: #e8fff3;
          }
        }
      }
      .filter-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 640px) {
          width: 280px;
          justify-content: center;
        }
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: -20px;
          left: 96.5%;
          transform: translate(-50%);
          border-width: 10px;
          border-style: solid;
          border-color: transparent transparent
            ${props => props.theme.colors.input} transparent;
        }
        position: absolute;
        top: 55px;
        right: 0;
        border: 0.5px solid ${props => props.theme.colors.input};
        background: ${props => props.theme.colors.background};
        -webkit-box-shadow: 0px 7px 15px rgb(0 0 0 / 7%);
        -moz-box-shadow: 0px 7px 15px rgb(0 0 0 / 7%);
        box-shadow: 0px 7px 15px rgb(0 0 0 / 7%);
        border-radius: 5px;
        width: 500px;
        z-index: 100000;
        .filter-content {
          width: 100%;
          height: 100%;
        }
      }
      .filter-search {
        background-color: #ffffff;
        box-shadow: 0px 7px 15px rgb(0 0 0 / 7%);
      }
    }

    .MuiBadge-badge {
      color: red;
      margin: 5px -2px;
    }
    .MuiBadge-anchorOriginTopRightRectangle {
      font-size: 20px;
      z-index: initial;
    }

    & .MuiFormControl-marginDense {
      margin: 0;
    }
    & .MuiOutlinedInput-input {
      padding-top: 9px;
      padding-bottom: 9px;
    }
    & .MuiOutlinedInput-root,
    .MuiOutlinedInput-root:focus {
      &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #2684ff;
      }
      border-radius: 50px;
      background: ${props => props.theme.colors.input} !important;
      color: ${props => props.theme.colors.placeholder} !important;
      border: 0.5px solid ${props => props.theme.colors.borderInput};
      outline: none;
    }
    & .MuiInputLabel-root {
      color: ${props => props.theme.colors.placeholder};
    }
    & .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 0.5px;
      border-color: #2684ff;
    }

    & .MuiFormControl-marginDense {
      margin: 0;
    }
    & .MuiOutlinedInput-input {
      padding-top: 9px;
      padding-bottom: 9px;
    }
    & .MuiOutlinedInput-root,
    .MuiOutlinedInput-root:focus {
      &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #2684ff;
      }
      border-radius: 5px;
      background: ${props => props.theme.colors.input} !important;
      color: ${props => props.theme.colors.placeholder};
      border: 0.5px solid ${props => props.theme.colors.borderInput};
      outline: none;
    }
    & .MuiInputLabel-root {
      color: ${props => props.theme.colors.placeholder};
    }
    & .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 0.5px;
      border-color: #2684ff;
    }
    /* TODO: end modifi */

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border: 0.5px solid transparent;
    }
    .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline {
      box-shadow: 0 0 0 0.5px #2684ff;
    }
    .MuiFormControl-root {
      background: ${props => props.theme.colors.input};
      border-radius: 5px;
    }
    .MuiInput-input {
      padding: 9px;
      border-bottom: none;
      color: ${props => props.theme.colors.placeholder};
    }
    .MuiInput-root {
      border-radius: 5px;
      border: 0.5px solid rgba(37, 75, 132, 0.1);
    }
    .MuiInput-root:focus-within {
      box-shadow: 0 0 0 1px #2684ff;
      border-color: transparent;
      .MuiInputBase-input {
      }
    }
    .MuiSvgIcon-root {
      color: ${props => props.theme.colors.placeholder};
      transition: all 0.3s;
    }
    .MuiCheckbox-root {
      .MuiSvgIcon-root {
        color: ${props => props.theme.colors.primary} !important;
        transition: all 0.3s;
      }
      .MuiTypography-root .MuiFormControlLabel-label .MuiTypography-body1 {
        color: ${props => props.theme.colors.title};
      }
    }
    .MuiInput-underline::before {
      content: none;
    }
    .MuiInput-underline::after {
      content: none;
    }

    &
      .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-fullWidth.secundaryButton {
      background: ${props => props.theme.colors.secundary};
      color: #f9f9f9;
      border-radius: 5px;
      /* border: 0.5px solid rgba(37, 75, 132, 0.25); */
      font-family: Poppins, sans-serif;
      box-shadow: none;
      font-weight: 500;
      text-transform: capitalize;
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-fullWidth.primaryButton {
      background: ${props => props.theme.colors.primary};
      color: #f9f9f9;
      border-radius: 5px;
      /* border: 0.5px solid rgba(37, 75, 132, 0.25); */
      font-family: Poppins, sans-serif;
      box-shadow: none;
      font-weight: 500;
      text-transform: capitalize;
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.secundaryButton {
      background: ${props => props.theme.colors.secundary};
      color: #f9f9f9;
      border-radius: 5px;
      /* border: 0.5px solid rgba(37, 75, 132, 0.25); */
      font-family: Poppins, sans-serif;
      box-shadow: none;
      font-weight: 500;
      text-transform: capitalize;
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.primaryButton {
      background: ${props => props.theme.colors.primary};
      color: #f9f9f9;
      border-radius: 5px;
      /* border: 0.5px solid rgba(37, 75, 132, 0.25); */
      font-family: Poppins, sans-serif;
      box-shadow: none;
      font-weight: 500;
      text-transform: capitalize;
    }

    .sel--is-disabled {
      .sel__control--is-disabled {
        /* background: ${props => props.theme.colors.input} !important; */
        background: #f2f2f2 !important;
        border-color: #e6e6e6 !important;
        /* color: ${props => props.theme.colors.placeholder} !important; */

        .sel__value-container {
          .sel__placeholder {
            color: #808080 !important;
          }
        }
      }
    }

    .sel__control {
      .sel__input {
        color: ${props => props.theme.colors.placeholderSelected} !important;
        font-size: 13px;
      }

      &:hover {
        border-color: ${props => props.theme.colors.borderInput} !important;
      }
    }
    .sel__menu {
      background: ${props => props.theme.colors.input};
      color: ${props => props.theme.colors.select_text};

      .sel__menu-list {
        font-size: 13px;
        .sel__option {
          color: ${props => props.theme.colors.select_text};
        }
        .sel__option--is-selected {
          background: ${props => props.theme.colors.primary};
          color: #ffffff;
        }

        .sel__option--is-focused {
          background: ${props => props.theme.colors.primary};
          color: ${props => props.theme.colors.text};
        }
      }
    }

    .input-wrapper input:focus {
      box-shadow: 0 0 0 0.5px #2684ff;
      border: 0.5px solid #2684ff;
    }

    .input-wrapper input::placeholder {
      font-size: 13px;
      line-height: 45px;
      font-weight: 400;
      color: ${props => props.theme.colors.placeholder};
    }
  }

  /* End SearchBox colors */

  .contentDetail {
    background: ${props => props.theme.colors.header_background};
    color: #505050;
    border-radius: 5px 5px 0 0;
    padding: 1rem 1rem 0 1rem;
    box-shadow: 0 7px 15px rgb(0 0 0 / 7%);

    .titleStatus {
      color: ${props => props.theme.colors.title} !important;
      font-family: Poppins;
      font-weight: 200 !important;
      font-size: 12px !important;
      display: flex;
      justify-content: center !important;
    }

    .detailHeader {
      display: inline;
      width: 100%;
      margin: 2.3rem 0;

      .headDetail {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-weight: 400;
          font-family: 'Poppins';
          font-weight: 400;
          font-size: 18px;
          color: #040404;
        }

        .titleNF {
          font-weight: 400;
          font-size: 16px;
          color: ${props => props.theme.colors.textTable};
        }

        #status {
          display: flex;
          align-items: center;
        }
      }

      p {
        font-size: 14px;
        margin-right: 6px;
        font-weight: 500;
      }

      .divider {
        background-color: ${props => props.theme.colors.line};
        width: 100%;
        height: 1px;
        margin: 1rem 0;
      }

      .details {
        display: flex;
        flex-direction: column;
        padding: 2rem 0 0 0;
        background: ${props =>
          props.theme.title === 'light'
            ? 'rgba(245, 248, 250, 0.4)'
            : '#2A2A3D'};
        border: 1px dashed
          ${props => (props.theme.title === 'light' ? '#e4e6ef' : '#191924')};
        border-radius: 6px;
        margin-bottom: 1rem;

        @media screen and (max-width: 700px) {
          > div {
            width: 100% !important;
            display: block !important;
            align-items: center !important;
            padding: 10px !important;
          }
        }

        #firstRow {
          margin: 0;
          .detailsItem {
            margin-right: 25px;
            :first-of-type {
              p:last-of-type {
                color: #f64e60;
              }
            }
            :last-of-type {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              p:last-of-type {
                color: #f64e60;
                font-weight: 400;
              }
            }
          }
        }
        .detailsItemsContainer {
          display: flex;
          justify-content: space-between;
          margin: 12px 0 20px 0;
          flex-wrap: wrap;
          padding: 0 10px;

          .totalTimeResume {
            .detailsItem {
              p:last-of-type {
                color: #46be7d;
                font-weight: 600;
              }
            }
          }

          .detailsItem {
            :last-of-type {
              display: flex;
              flex-direction: column;
            }
            p {
              :first-of-type {
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 12px;
                color: ${props =>
                  props.theme.title === 'light' ? '#7E8299' : '#EFF2F5'};
              }
              :last-of-type {
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 18px;
                color: ${props =>
                  props.theme.title === 'light' ? '#3F4254' : '#B5B5C3'};
                opacity: 0.7;
              }
            }
          }
        }
      }

      .volume {
        display: flex;
        margin: 5px 5px 20px 5px;
        flex-wrap: wrap;
        align-items: center;

        .detailsCards {
          display: flex;
          gap: 10px;
          .success {
            border: dotted 1px #1bc5bd;
          }
          .danger {
            border: dotted 1px #f65464;
          }
          div {
            display: flex;
            flex-direction: column;
            border: dotted 1px #e4e6ef;
            border-radius: 5px;
            padding: 10px 0 10px 5px;
            white-space: nowrap;
            font-family: 'Roboto';
            span {
              color: #3f4254;
              font-size: 24px;
              font-weight: 900;
            }
            p {
              color: #b5b5c3;
              border: none;
              border-radius: none;
              padding: 0;
              white-space: nowrap;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
        p {
          border: dotted 1px #7b7b7b;
          border-radius: 5px;
          padding: 10px;
          flex: 1;
          white-space: nowrap;
          font-size: 12px;
        }
      }
      span {
        margin: 0 5px 0 5px;
      }
      .primary {
        color: ${props => props.theme.colors.primary};
      }

      .secundary {
        color: ${props => props.theme.colors.secundary};
      }
      .titleDetail {
        color: ${props => props.theme.colors.title};
      }
    }
  }

  .title {
    color: ${props => props.theme.colors.title};
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    margin: 1.5rem 0rem;
    width: initial;
  }

  .labelInput {
    color: ${props => props.theme.colors.title};
    margin: 0 2px 7px 7px;
    font-weight: 400;
    font-size: 13px;
  }

  .MuiBadge-badge {
    color: red;
    margin: 5px -2px;
  }
  .MuiBadge-anchorOriginTopRightRectangle {
    font-size: 20px;
    z-index: initial;
  }

  & .MuiFormControl-marginDense {
    margin: 0;
  }
  & .MuiOutlinedInput-input {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  & .MuiOutlinedInput-root,
  .MuiOutlinedInput-root:focus {
    &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: #2684ff;
    }
    border-radius: 5px;
    background: ${props => props.theme.colors.input};
    color: ${props => props.theme.colors.placeholder};
    border: 0.5px solid ${props => props.theme.colors.borderInput};
    outline: none;
  }
  & .MuiInputLabel-root {
    color: ${props => props.theme.colors.placeholder};
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 0.5px;
    border-color: #2684ff;
  }

  & .MuiFormControl-marginDense {
    margin: 0;
  }
  & .MuiOutlinedInput-input {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  & .MuiOutlinedInput-root,
  .MuiOutlinedInput-root:focus {
    &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: #2684ff;
    }
    border-radius: 5px;
    background: ${props => props.theme.colors.input};
    color: ${props => props.theme.colors.placeholder};
    border: 0.5px solid ${props => props.theme.colors.borderInput};
    outline: none;
  }
  & .MuiInputLabel-root {
    color: ${props => props.theme.colors.placeholder};
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 0.5px;
    border-color: #2684ff;
  }
  /* TODO: end modifi */

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 0.5px solid transparent;
  }
  .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline {
    box-shadow: 0 0 0 0.5px #2684ff;
  }
  .MuiFormControl-root {
    background: ${props => props.theme.colors.input};
    border-radius: 5px;
  }
  .MuiInput-input {
    padding: 9px;
    border-bottom: none;
    color: ${props => props.theme.colors.placeholderSelected} !important;
  }
  .MuiInput-root {
    border: 0.5px solid ${props => props.theme.colors.borderInput} !important;
    .MuiInputBase-input .MuiInput-input .MuiInputBase-inputAdornedEnd {
      color: ${props => props.theme.colors.placeholderSelected} !important;
    }
    border-radius: 5px;
  }
  .MuiInput-root:focus-within {
    box-shadow: 0 0 0 1px #2684ff;
    border-color: transparent;
    .MuiInputBase-input {
    }
  }
  .MuiSvgIcon-root {
    color: ${props => props.theme.colors.placeholder};
    transition: all 0.3s;
  }
  .MuiCheckbox-root {
    .MuiSvgIcon-root {
      color: ${props => props.theme.colors.primary} !important;
      transition: all 0.3s;
    }
    .MuiTypography-root .MuiFormControlLabel-label .MuiTypography-body1 {
      color: ${props => props.theme.colors.title};
    }
  }
  .MuiInput-underline:before {
    content: none;
  }
  .MuiInput-underline:after {
    content: none;
  }

  &
    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-fullWidth.secundaryButton {
    background: ${props => props.theme.colors.secundary};
    color: #f9f9f9;
    border-radius: 5px;
    /* border: 0.5px solid rgba(37, 75, 132, 0.25); */
    font-family: Poppins, sans-serif;
    box-shadow: none;
    font-weight: 500;
    text-transform: capitalize;
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-fullWidth.primaryButton {
    background: ${props => props.theme.colors.primary};
    color: #ffffff;
    border-radius: 5px;
    /* border: 0.5px solid rgba(37, 75, 132, 0.25); */
    font-family: Poppins, sans-serif;
    box-shadow: none;
    font-weight: 500;
    text-transform: capitalize;
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.secundaryButton {
    background: ${props => props.theme.colors.secundary};
    color: #ffffff;
    border-radius: 5px;
    /* border: 0.5px solid rgba(37, 75, 132, 0.25); */
    font-family: Poppins, sans-serif;
    box-shadow: none;
    font-weight: 500;
    text-transform: capitalize;
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.primaryButton {
    background: #0095e8 !important;
    color: #ffffff;
    border-radius: 6px;
    /* border: 0.5px solid rgba(37, 75, 132, 0.25); */
    font-family: Poppins, sans-serif;
    box-shadow: none;
    font-weight: 500;
    width: 7.25rem;
    height: 2.625rem;
    text-transform: capitalize;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.primaryButton:hover {
    background: #3fbaff !important;
  }
  .wrapper {
    width: 100%;

    .wrap {
      overflow-x: clip;
      /* background: ${props => props.theme.colors.background}; */

      .table {
        border-radius: 5px !important;
        box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.07);
        color: ${props => props.theme.colors.textTable} !important;
        border: none;
        transition: color 0.3s !important;
        transition-duration: 0.3s !important;
        border-color: ${props => props.theme.colors.line} !important;
        transition-delay: 0ms !important;
      }

      .exportListContent {
        background: ${props => props.theme.colors.input};
        color: ${props => props.theme.colors.placeholderSelected};
      }

      .tableWrapperBox {
        border-radius: 7px;
        table {
          border-radius: 4px !important;

          thead {
            height: 30px !important;

            tr {
              /* TODO: Header differ Zebra Table color rows */
              /* background: #f2f2f2 !important; */
              height: 10px !important;

              th {
                padding: 8px 8px;
              }
            }
          }

          /* .title {
            h1 {
              color: ${props => props.theme.colors.title};
            }
          } */

          tbody {
            tr {
              /* &:nth-child(2n + 1) {
            /* TODO: Zebra Table color rows */
              /* background: #fbfbfb; */
            }
            */ td {
              border-top: 1px solid ${props => props.theme.colors.line};

              & + td {
                border-left: 1px solid ${props => props.theme.colors.line};
              }
              padding: 7px 0;

              &:last-child {
                width: 10%;
              }

              .hyperLinkStyle {
                a {
                  color: ${props => props.theme.colors.hyperLink};
                }

                svg {
                  path {
                    fill: ${props => props.theme.colors.hyperLink};
                    stroke: ${props => props.theme.colors.hyperLink};
                  }

                  circle {
                    stroke: ${props => props.theme.colors.hyperLink};
                  }
                }
              }

              .boolean-item {
                .yes,
                .no {
                  width: 65px;
                  height: 26px;
                  background: ${props => props.theme.colors.success};
                  border-radius: 6px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  p {
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 16px;
                    color: ${props => props.theme.colors.successText};
                  }
                }

                .no {
                  background: ${props => props.theme.colors.error};

                  p {
                    color: #ffa800;
                  }
                }
              }

              .code-item {
                display: flex;
                align-items: center;

                p {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  color: #464e5f;
                }
              }

              .item {
                p {
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 19px;
                  color: #b5b5c3;
                }
              }

              .detail {
                display: flex;
                justify-content: center;
              }

              .row-actions-item {
                display: flex;
                justify-content: flex-end;

                .action {
                  width: 32px;
                  height: 32px;
                  border-radius: 8px;
                  padding: 8px !important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: transparent;
                  position: relative;
                  transition: 0.15s ease;

                  .hover-item {
                    visibility: hidden;
                    position: absolute;
                    opacity: 0;
                    bottom: 40px;
                    background: #000;
                    padding: 8px 16px;
                    border-radius: 4px;
                    box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.1);
                    -webkit-box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.1);
                    -moz-box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.1);
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 19px;
                    color: #1e1e2d;
                    left: 50%;
                    transform: translateX(-50%) translateY(-5px);
                    z-index: 10;
                    transition: 0.3s;
                  }

                  &:hover {
                    background-color: #f2f2f2;

                    .hover-item {
                      opacity: 1;
                      visibility: visible;
                      transform: translateX(-50%) translateY(0);
                    }
                  }

                  & + .action {
                    margin-left: 10px;
                  }

                  img {
                    max-width: 50%;
                    border-radius: 8px;
                  }
                }
              }

              .row-actions-button-detail {
                display: flex;
                justify-content: center;

                p {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                }

                .action {
                  position: relative;
                  width: 32px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: transparent;
                  transition: 0.15s ease;

                  .hover-item {
                    visibility: hidden;
                    position: absolute;
                    bottom: 40px;
                    background: ${props =>
                      props.theme.title === 'light' ? '#EEF0F8' : '#191924'};
                    padding: 8px 16px;
                    border-radius: 4px;
                    box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.1);
                    opacity: 0;
                    transform: translateX(-50%) translateY(-5px);
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 19px;
                    color: ${props =>
                      props.theme.title === 'light' ? '#6C7293' : '#FFFFFF'};
                    left: 50%;
                    transform: translateX(-50%);
                    transition: 0.15s ease;
                  }

                  &:hover {
                    background: ${props =>
                      props.theme.title === 'light' ? '#f2f2f2' : '#222'};

                    .hover-item {
                      opacity: 1;
                      visibility: visible;
                      transform: translateX(-50%) translateY(0);
                    }
                  }

                  & + .action {
                    margin-left: 10px;
                  }

                  img {
                    max-width: 50%;
                  }
                }
              }

              .row-actions-button {
                display: flex;
                align-items: center;

                p {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                }

                .action {
                  width: 32px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: transparent;
                  position: relative;
                  transition: 0.15s ease;

                  .hover-item {
                    visibility: hidden;
                    position: absolute;
                    bottom: 40px;
                    background: #fff;
                    padding: 8px 16px;
                    border-radius: 4px;
                    box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.1);
                    -webkit-box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.1);
                    -moz-box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.1);
                    opacity: 0;
                    transform: translateX(-50%) translateY(-5px);
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 19px;
                    color: #1e1e2d;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: 0.15s ease;
                  }

                  &:hover {
                    background: ${props =>
                      props.theme.title === 'light' ? '#f2f2f2' : '#222'};

                    .hover-item {
                      opacity: 1;
                      visibility: visible;
                      transform: translateX(-50%) translateY(0);
                    }
                  }

                  & + .action {
                    margin-left: 10px;
                  }

                  img {
                    max-width: 50%;
                  }
                }
              }
              p {
                color: ${props => props.theme.colors.textTable};
              }
            }
          }
        }
      }
      .pagination-box {
        .pagination {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-left: 1rem;

          .arrow {
            width: 30px;
            height: 30px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            transition: all 0.3s;

            &:hover {
              background: #408cfe;
              color: #fff;

              img {
                filter: invert(97%) sepia(12%) saturate(463%) hue-rotate(221deg)
                  brightness(115%) contrast(100%);
              }
            }

            & + .arrow {
              margin-left: 4px;
            }

            img {
              max-width: 10px;
            }

            &:disabled {
              background: transparent;
              opacity: 0.2;
              cursor: default;
            }
          }

          .double-arrow {
            &:first-child {
              transform: rotate(-180deg);
            }
          }

          .links {
            padding: 0 1px;
            display: flex;
            flex-wrap: wrap;

            button {
              &:hover {
                background: #408cfe !important;
                color: #fff;
              }
              color: ${props => props.theme.colors.textTable};
            }
          }
        }

        .pages {
          width: auto;
          display: flex;
          align-items: center;

          .select {
            width: 70px;
            .sel__control {
              background: ${props => props.theme.colors.input};
              border-radius: 5px;
              border-color: rgba(37, 75, 132, 0.25);
            }

            .sel__menu {
              background: ${props => props.theme.colors.input};
              color: ${props => props.theme.colors.select_text};

              .sel__menu-list {
                font-size: 13px;
                .sel__option {
                  color: ${props => props.theme.colors.select_text};
                }
                .sel__option--is-selected {
                  background: ${props => props.theme.colors.primary};
                  color: #ffffff;
                }

                .sel__option--is-focused {
                  background: ${props => props.theme.colors.primary};
                  color: ${props => props.theme.colors.text};
                }
              }
            }
          }

          .text {
            p {
              margin-left: 16px;
              font-size: 13px;
              color: ${props => props.theme.colors.textTable};
              line-height: 20px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
`;

export const RightButons = styled.div`
  .advanced {
    margin-right: 1rem;
  }
`;

export const BaseIcon = styled.div<IconProps>`
  display: flex;
  width: 40px;
  height: 40px;
  margin: auto;
  align-items: center;
  position: relative;
  justify-content: center;
  @media (max-width: 1380px) {
    width: 30px;
    height: 30px;
    margin: 0px;
  }

  background-color: ${props =>
    (props.titulo === 'Estoque' && '#20d26880') ||
    (props.titulo === 'Endereços utilizados' && '#2f80ed80') ||
    (props.titulo === 'Pedidos' && '#cf454580') ||
    (props.titulo === 'Picking Planejado' && '#f8a63c80') ||
    (props.titulo === 'Picking Divergente' && '#FF737380') ||
    (props.titulo === 'Conferência' && '#bb6bd980') ||
    (props.titulo === 'Expedição' && '#ff737380') ||
    (props.titulo === 'Insuficiente' && '#b4434380') ||
    (props.titulo === 'Picking Andamento' && '#ffb70080')};
  border-radius: 20%;

  .Estoque {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-55%, -40%);
  }

  .Pedidos {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
  }
`;

export const CardContainer = styled(Fade)<{ porcentagem: number }>`
  position: relative;
  .progress-bar {
    align-self: center;
    text-align: center;
    svg {
      max-width: 100%;
      g,
      circle {
        max-width: 100%;
        stroke: ${({ theme, porcentagem }) => {
          if (porcentagem === undefined) {
            return 'rgba(218, 218, 218, 0.2)';
          }
          if (porcentagem === 0) {
            return theme.title === 'light'
              ? 'rgba(218, 218, 218, 0.2)'
              : '#3F4254';
          }
          if (porcentagem > 1 && porcentagem < 50) {
            return 'rgba(246, 155, 17, 0.2)';
          }
          if (porcentagem > 50 && porcentagem < 100) {
            return 'rgba(255, 199, 0, 0.2)';
          }
          if (porcentagem === 100) {
            return '#46BE7D';
          }
          return 'rgba(218, 218, 218, 0.2)';
        }} !important;
      }
    }

    .progress-value {
      h2 {
        color: ${props =>
          props.theme.title === 'light' ? '#6C7293' : '#fff'} !important;
        font-size: clamp(1em, 24px, 5vmin) !important;
        font-weight: 400 !important;
        margin-bottom: -5px;
      }
      span {
        color: ${props =>
          props.theme.title === 'light' ? '#959CB6' : '#EEF0F8'} !important;
        font-weight: 400 !important;
        font-size: 14px !important;
      }
    }
  }
`;

export const DashboardCard = styled.div<IconProps>`
  .dashboardCard {
    display: flex;
    position: relative;
    background-color: ${props => props.theme.colors.header_background};
    box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    border-radius: 12px;
    min-height: 250px !important;
    color: #262626;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 1rem;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    @media (max-width: 1380px) {
      height: 100px;
    }
    .icon {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 5px;
      @media (max-width: 1380px) {
        padding: 0;
      }

      .information {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 1rem;
        justify-content: flex-start;
        h4 {
          font-size: 14px !important;
          font-weight: 500 !important;
          color: ${props =>
            props.theme.title === 'light' ? '#6C7293' : '#FFF'} !important;
        }
        p {
          font-size: 15px !important;
          color: ${props =>
            props.theme.title === 'light' ? '#959CB6' : '#EEF0F8'} !important;
        }
        @media (max-width: 1380px) {
          font-size: 11px;
          margin-left: 10px;
        }
      }
      .total {
        display: flex;
        justify-content: center;
        max-width: 70px;
        width: 100%;
        padding: 0.7rem;
        border-radius: 20%;
        font-weight: 500;
        margin-left: auto;
        background-color: ${props => props.theme.colors.counter_background};
        color: ${props => props.theme.colors.counter_color};
        font-size: 14px;
        @media (max-width: 1380px) {
          max-width: 50px;
          padding: 0.5rem;
          font-size: 10px;
        }
        /* color: ${props => props.theme.colors.title}; */
      }
      p {
        color: ${props => props.theme.colors.title};
        display: flex;
      }
      h4 {
        font-size: 14px;
        font-weight: 400;
        color: ${props =>
          props.theme.title === 'light' ? '#6C7293' : '#FFFFFF'};
      }
    }

    &:hover {
      transform: scale(1.02);
      .icon {
        .total {
          background-color: ${props =>
            (props.titulo === 'Estoque' && '#20d26880') ||
            (props.titulo === 'Endereços utilizados' && '#2f80ed80') ||
            (props.titulo === 'Pedidos' && '#cf454580') ||
            (props.titulo === 'Picking Planejado' && '#f8a63c80') ||
            (props.titulo === 'Picking Divergente' && '#FF737380') ||
            (props.titulo === 'Conferência' && '#bb6bd980') ||
            (props.titulo === 'Expedição' && '#ff737380') ||
            (props.titulo === 'Insuficiente' && '#b4434380') ||
            (props.titulo === 'Picking Andamento' && '#ffb70080')} !important;
          color: ${props => props.theme.colors.counter_color};
          /* color: ${props => props.theme.colors.title}; */
        }
      }
    }
  }
`;

export const ContainerGraph = styled.div`
  width: 100%;
  height: 400px;
  background: ${props => props.theme.colors.background};
  box-shadow: 0px 7px 15px rgb(0 0 0 / 7%);
  margin: 0;
  margin-top: 1.5rem;
  border-radius: 5px;

  & > svg {
    border-radius: 5px;
  }
`;

export const Search = styled.div`
  width: 100%;
  margin: 0;
  background: ${props => props.theme.colors.background};
  border-radius: 5px;
  box-shadow: 0px 7px 15px rgb(0 0 0 / 7%);

  .sel--is-disabled {
    .sel__control--is-disabled {
      /* background: ${props => props.theme.colors.input} !important; */
      background: #f2f2f2 !important;
      border-color: #e6e6e6 !important;
      /* color: ${props => props.theme.colors.placeholder} !important; */

      .sel__value-container {
        .sel__placeholder {
          color: #808080 !important;
        }
      }
    }
  }

  /* TODO: Controll */

  .sel__control {
    transition: none;
    background: ${props => props.theme.colors.input};
    border-radius: 5px;
    border: 0.5px solid ${props => props.theme.colors.borderInput};

    .sel__value-container {
      .sel__placeholder {
        font-size: 13px;
        color: ${props => props.theme.colors.placeholder};
      }
      .sel__single-value {
        font-size: 13px;
        color: ${props => props.theme.colors.placeholderSelected} !important;
      }
    }
    .sel__input {
      color: ${props => props.theme.colors.placeholderSelected} !important;
      font-size: 13px;
    }
  }

  .sel__menu {
    background: ${props => props.theme.colors.input};
    color: ${props => props.theme.colors.select_text};

    .sel__menu-list {
      font-size: 13px;
      .sel__option {
        color: ${props => props.theme.colors.select_text};
      }
      .sel__option--is-selected {
        background: ${props => props.theme.colors.primary};
        color: #ffffff;
      }

      .sel__option--is-focused {
        background: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.text};
      }
    }
  }
`;

export const Total = styled.div`
  width: 100%;
  margin: 2.3rem 0;
  margin-top: 3rem;
  background: ${props => props.theme.colors.total};
  border-radius: 5px;
  box-shadow: 0px 7px 15px rgb(0 0 0 / 7%);

  p {
    font-size: 13px;
    white-space: nowrap;
    text-align: center;
    color: ${props => props.theme.colors.details};

    span {
      margin-left: 1rem;
      color: ${props => props.theme.colors.title};
    }
  }
`;

export const ExportButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  button {
    background-color: ${props =>
      props.theme.title === 'dark' ? '#212E48' : '#ecf8ff'};
    border-radius: 6px;
    padding: 8px 16px;
    text-transform: unset !important;
    &:hover {
      background-color: #3fbaff;
      transition: 0.5s;
      box-shadow: none;
      p {
        color: #ffffff !important;
      }
      path {
        fill: #ecf8ff;
      }
    }
  }
  p {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 12px;
    color: #0095e8;
    margin-left: 8px;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  align-items: flex-end;

  .inputDesc {
    font-weight: 400;
    font-size: 12px;
    color: #b5b5c3;
  }
`;

export const FilterButtons = styled.div`
  max-width: 100%;
  min-width: 100%;
  padding: 0 15px 15px 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 10px;

  button {
    text-transform: unset !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 12px;
    padding: 10px 25px;
    /* margin-left: 10px; */
    border-radius: 6px;
    box-shadow: none;
    p {
      margin-left: 9px;
    }
    &:hover {
      transition: 0.5s;
      box-shadow: none;
    }
  }
  .advancedFilterButton {
    color: ${props => (props.theme.title === 'light' ? '#46be7d' : '#04aa77')};
    background-color: ${props =>
      props.theme.title === 'light' ? '#e8fff3' : '#1C3238'};
    padding: 13px 16px;
    &:hover {
      background-color: #50cd89;
      color: #fff;
      path {
        fill: #e8fff3;
      }
    }
  }
  .closeFilterButton {
    color: ${props => (props.theme.title === 'light' ? '#f69b11' : '#EE9D01')};
    background-color: ${props =>
      props.theme.title === 'light' ? '#fff5e6' : '#392F28'};
    padding: 13px 16px;
    &:hover {
      background-color: #ee9d01;
      color: #fff5e6;
      path {
        fill: #fff5e6;
      }
    }
  }
  .clearButton {
    background-color: ${props =>
      props.theme.colors.tagStatusColors.finalizado_divergente_or_bloqueado.bg};
    color: ${props =>
      props.theme.colors.tagStatusColors.finalizado_divergente_or_bloqueado
        .color};
    padding: 13px 16px;
    &:hover {
      background-color: #f1416c;
      color: #fff5f8;
      path {
        fill: #fff5f8;
      }
    }
  }
  .searchButton {
    background-color: ${props =>
      props.theme.title === 'light' ? '#0095E8' : '#187DE4'};
    color: #fff;
    padding: 13px 16px;
    &:hover {
      background-color: ${props =>
        props.theme.title === 'light' ? '#3fbaff' : '#0095E8'};
      color: #fff;
    }
    &:disabled {
      background-color: #0095e8;
      opacity: 0.6;
    }
  }
  .popUp {
    margin-right: 10px;
  }

  @media screen and (max-width: 600px) {
    justify-content: center;
    gap: 8px;
    flex-direction: column;
    padding: 8px;
    & > .MuiButtonBase-root {
      width: 100%;
    }
  }
`;

interface IEditStatusButtonContainerProps {
  buttonBackgroundColor?: string;
  buttonTitleColor?: string;
  buttonHoverColor?: string;
  buttonHoverTitleColor?: string;
}

export const EditStatusButtonContainer = styled.div<IEditStatusButtonContainerProps>`
  button {
    background-color: ${props =>
      props.buttonBackgroundColor || '#212E48'} !important;
    border-radius: 6px;
    padding: 8px 16px;
    text-transform: unset !important;
    gap: 8px;
    &:hover {
      transition: 0.5s;
      box-shadow: none;
      background-color: ${props =>
        props.buttonHoverColor || '#3fbaff'} !important;
      path {
        fill: ${props => props.buttonHoverTitleColor || '#ecf8ff'};
      }
      p {
        color: ${props => props.buttonHoverTitleColor || '#ecf8ff'};
      }
    }
    p {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 12px;
      margin-left: 10px;
      color: ${props => props.buttonTitleColor || '#0095e8'};
    }
  }
`;

export const TableFilterCardContainer = styled.div<{
  cardProgressValue?: number;
  withPadding?: boolean;
  removePadding?: boolean;
}>`
  display: flex;
  padding: ${props =>
    props.withPadding
      ? '0 0 35px 0'
      : props.removePadding
      ? '0'
      : '5px 16px 20px 16px'};
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  button {
    all: unset;
    cursor: pointer;
  }

  background: ${props => props.theme.colors.header_background};

  div {
    background: ${props => props.theme.colors.header_background};
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    :last-of-type {
      margin-right: 0;
    }
    #done {
      border-color: #46be7d;
    }
    #divergent {
      border-color: #d9214e;
    }
    #divergent-highlight {
      border-color: #d9214e;
      background-color: #ffc700;
      p {
        color: ${props => (props.theme.title === 'light' ? '#3f4254' : '#fff')};
        z-index: 2;
      }
    }
    #ongoing {
      border-color: #ffc700;
    }
    #waiting {
      border-color: #f69b11;
    }
    #canceled {
      border-color: #3f4254;
    }
    #pending {
      border-color: #a1a5b7;
    }
    #expeditionCompleted {
      border-color: #4a7dff;
    }
    #resupply {
      border-color: #58244b;
    }
    #totalProgress {
      border-color: ${props => {
        let progressBg = props.cardProgressValue;
        if (progressBg === undefined) {
          progressBg = 10;
        }
        if (progressBg === 100) {
          return '#46BE7D';
        }
        if (progressBg < 50) {
          return '#D9214E';
        }
        if (progressBg <= 99) {
          return '#FFC700';
        }
        return '#F8B44C';
      }};
      position: relative;
      p {
        color: ${props => (props.theme.title === 'light' ? '#3f4254' : '#fff')};
        z-index: 2;
      }
    }
    .tableFilterCard {
      display: flex;
      flex-direction: column;
      min-width: 140px;
      border: 1px dashed #0095e8;
      border-radius: 6px;
      padding: 12px 15px;
      p {
        user-select: none;
        :first-of-type {
          font-family: 'Roboto';
          font-weight: 900;
          font-size: 24px;
          color: ${props => props.theme.colors.title};
        }
        :last-of-type {
          font-family: 'Roboto';
          font-weight: 500;
          font-size: 12px;
          color: #b5b5c3;
        }
      }
      span {
        border-radius: 6px;
        width: ${props => `${props.cardProgressValue}%`};
        top: 0;
        left: 0;
        height: 100%;
        background: ${props => {
          let progressBg = props.cardProgressValue;
          if (progressBg === undefined) {
            progressBg = 10;
          }
          if (progressBg === 100) {
            return '#46BE7D';
          }
          if (progressBg < 50) {
            return '#D9214E';
          }
          if (progressBg <= 99) {
            return '#FFC700';
          }
          return '#F8B44C';
        }};
        position: absolute;
      }
    }
  }
  @media screen and (max-width: 660px) {
    padding: 10px;
    gap: 5px;
    button,
    .detailsItem.tableFilterCard {
      width: 100%;
    }
    div {
      width: 100%;
      gap: 5px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.3rem;

  h1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: ${props => props.theme.colors.title};
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.3rem;

  .buttonFooter {
    & + .buttonFooter {
      margin-left: 1rem;
    }
  }
`;

const loadingAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .verticalDivider {
    height: 20px !important;
    background: ${props => props.theme.colors.line};
  }

  button {
    width: 30px;
    padding: 6px;
    height: 30px;
    background: ${props => props.theme.colors.actions};

    &:hover {
      // eslint-disable-next-line prettier/prettier
      background: ${props => props.theme.colors.actions};
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .replaceButton {
    &.active {
      background: ${props => props.theme.colors.primary} !important;
      color: #fff !important;

      svg {
        fill: #fff !important;
        stroke: #fff !important;
        animation: ${loadingAnimation} 1.5s linear infinite;
      }
    }
  }
`;

export const ContainerTab = styled.div`
  width: 100%;
  /* height: 325px; */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: ${props => props.theme.colors.background};
`;

export const ContainerSimpleTabs = styled.div`
  width: 100%;
  height: 375px;
  margin-top: 2.3rem;
  border-radius: 15px;
  background: ${props => props.theme.colors.background};
`;

export const TableContainer = styled.div<{ inPopUp?: boolean }>`
  padding: ${props => (props.inPopUp ? '3px 1rem' : '1rem')};
  color: ${props => props.theme.colors.textTable};
  background-color: ${props => props.theme.colors.table_bg};
  width: 100% !important;

  .MuiPaper-root
    .MuiAppBar-root
    .MuiAppBar-positionStatic
    .MuiAppBar-colorPrimary
    .MuiPaper-elevation4,
  .MuiAppBar-colorPrimary {
    background-color: ${props => props.theme.colors.table_bg} !important;
    border-radius: 0px !important;
  }

  & > * {
    width: 100%;
    .actionsContainer {
      gap: 10px;
      padding-top: 24px;
      .MuiIconButton-root {
        padding: 10px !important;
        /* Update colors in light.ts and dark.ts */
        background-color: #f2f2f2 !important;
      }

      .MuiButtonBase-root {
        .MuiIconButton-root {
          padding: 10px !important;
          background-color: #f2f2f2 !important;
          border-radius: none !important;
        }
      }
    }
    .beforeExport {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-right: 17px;
      margin-bottom: 0.7rem;
      .select {
        width: 37%;
      }
      button {
        background-color: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.text};
      }
    }

    .MuiTabs-flexContainer {
      background-color: ${props => props.theme.colors.table_bg};

      .MuiButtonBase-root {
        background-color: ${'#ECF8FF'};
        color: ${'#0095E8'};
        border-radius: 6px;
        margin-left: 5px;
        border: none;

        &:last-child {
          margin-left: 10px;
        }
      }
    }

    .render-number,
    .render-string {
      font-weight: 400;
      line-height: 18px;
      font-size: 13px;
      font-family: 'Poppins', sans-serif;
      color: ${props => props.theme.colors.textTable};
      padding: 0 8px;
    }

    .render-number {
      text-align: right;
      padding: 0 8px;
    }

    .detailTableWrap {
      display: flex;
      align-items: center;
      margin-bottom: 0.7rem;

      p {
        margin-left: 0.7rem;
      }
    }
  }
`;

export const TableContent = styled.div``;
