import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { LooseAutoComplete } from 'helpers/types';
import { setCookie, getCookie } from 'utils/cookies';
import { useTema } from 'hooks/Theme';
import { LogoLoader } from '../components/Tools/Loader/styles';
import { useAuth } from '../hooks/Auth';
import { Container } from './styles';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

type QuerysName =
  | 'token'
  | 'codigoCliente'
  | 'client'
  | 'exception'
  | 'code'
  | 'theme';

type Querys = LooseAutoComplete<QuerysName>;

const Token: React.FC = () => {
  const query = useQuery();
  const location = useHistory();

  const { signIn } = useAuth();
  const { setTheme } = useTema();
  const { enqueueSnackbar } = useSnackbar();

  const getQuery = useCallback(
    (queryName: Querys) => {
      const paramQuery = query.get(queryName.toString());

      if (!paramQuery) return '';

      return String(paramQuery);
    },
    [query]
  );

  useEffect(() => {
    const date = new Date().toString();
    const queryTheme = getQuery('theme') === 'light' ? 'light' : 'dark';

    setTheme(queryTheme);

    signIn({
      token_acc: getQuery('token'),
      cliente: getQuery('codigoCliente'),
    })
      .then(() => {
        const active = getCookie('@pdamodules::active');

        setCookie('@pdamodules::CodigoSistema', getQuery('code'), 90000);
        setCookie('@pdamodules::descricaoCliente', getQuery('client'), 9000);

        const exception = getQuery('exception');

        const isExpired = parseInt(exception, 10) <= Date.parse(date);

        if (active === 'true' && !isExpired) {
          location.push('/wms/home/');
        } else {
          enqueueSnackbar('Sua seção expirou! Faça o login novamente.', {
            variant: 'warning',
          });
          window.location.replace(
            `${
              process.env.REACT_APP_ENV === 'dev'
                ? process.env.REACT_APP_LOGOUT_DEV_URL
                : process.env.REACT_APP_LOGOUT_PROD_URL
            }`
          );
        }
      })
      .catch(err => {
        enqueueSnackbar(`Erro: ${err.message}`, {
          variant: 'error',
        });
      });
  }, [enqueueSnackbar, getQuery, location, setTheme, signIn]);

  return (
    <Container>
      <div className="container">
        <LogoLoader width="70px" height="70px">
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            id="content"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 2C7.84987 2.44222 11.4095 3.18865 15 4.63644C23.5883 8.09953 32.3526 15.5755 32 32.5"
              stroke="#E12325"
              strokeWidth="2.5"
              strokeLinecap="round"
              className="lastLine"
            />
            <circle
              cx="3.5"
              cy="29.6"
              r="3"
              fill="#E12325"
              className="circle"
            />
            <path
              d="M3 19.5C6.66667 19.8333 14 22.7 14 31.5"
              stroke="#E12325"
              strokeWidth="2.5"
              strokeLinecap="round"
              className="firstLine"
            />
            <path
              d="M4 10C10.4969 10.1705 23.3907 14.8093 22.9909 32"
              stroke="#E12325"
              strokeWidth="2.5"
              strokeLinecap="round"
              className="middleLine"
            />
          </svg>
        </LogoLoader>
      </div>
    </Container>
  );
};

export default Token;
