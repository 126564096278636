import styled, { css, keyframes } from 'styled-components';
import { darken } from 'polished';

export const ExportButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-size: 13px;
  text-transform: initial;
  margin-bottom: 1rem;

  & + button {
    margin-left: 6px;
  }

  .exportBtn {
    rect,
    path,
    svg {
      fill: #3699ff;
    }
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 7px;
    line-height: 18px;
    width: 100px;
    height: 35px;

    margin-top: 3px;
    background: transparent;
    color: #3699ff;
    font-size: 13px;
    text-transform: initial;

    transition: all 0.3s;
    &:hover {
      transition: background 0.2s;
      background: ${darken(0.05, '#3699FF')};
      color: #ffff;

      rect,
      path,
      svg {
        fill: #fff;
      }
    }

    & + button {
      margin-left: 6px;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* padding: 0.9rem 1.3rem; */
  justify-content: flex-end;
  margin-bottom: 0.4rem;
  gap: 10px;
  .menuExport {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-transform: capitalize;
  }

  .filterStyles {
    display: flex;
    flex-direction: row;
    width: 2rem;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 28px;
    margin-right: 60px;
  }

  .containerSearch {
    width: 100%;
    margin: 0;
    background: ${props => props.theme.colors.header_background} !important;
    border-radius: 5px;
    box-shadow: none !important;

    .advancedContent {
      margin-left: 1rem;
    }

    .filter-box {
      background: ${props => props.theme.colors.header_background} !important;
    }

    .filter-header {
      h1 {
        color: ${props => props.theme.colors.title} !important;
        font-size: 18px;
      }
    }

    .searchTitle {
      padding-left: 0.7rem;
      .returnClick {
        display: flex;
        align-items: center;
        h1 {
          margin-left: 1rem;
        }
      }

      h1 {
        color: ${props => props.theme.colors.title} !important;
      }
    }

    .dividerSearch {
      background: ${props => props.theme.colors.line} !important;
    }

    .labelInput {
      color: #b5b5c3;
      margin: 0 2px 5px 7px;
      font-weight: 400;
      font-size: 13px;
    }
    .containerFilter {
      display: flex;
      justify-content: center;
      .filter-Btn-Create {
        margin-left: 12px;
        @media (max-width: 640px) {
          font-size: 10px;
          margin-left: 16px;
        }
      }
      .filter-Btn-Create + .filter-Btn-Create {
        margin-left: 1rem;
      }
    }
    .MuiIconButton-label {
      justify-content: center;
    }
    .filter-row {
      position: relative;
      .filter-header {
        width: 100%;
        border-bottom: 0.5px solid ${props => props.theme.colors.line};
        padding: 1rem;
        display: flex;
        align-items: center;
        color: ${props => props.theme.colors.title};
        justify-content: space-between;
      }
      .filterButton {
        background-color: #e8fff3;
        p {
          color: #46be7d;
          font-family: 'Poppins';
          font-weight: 500;
          font-size: 12px;
        }
        &:hover {
          transition: 0.5s;
          background-color: #50cd89;
          p {
            color: #e8fff3;
          }
          path {
            fill: #e8fff3;
          }
        }
      }
      .filter-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 640px) {
          width: 280px;
          justify-content: center;
        }
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: -20px;
          left: 96.5%;
          transform: translate(-50%);
          border-width: 10px;
          border-style: solid;
          border-color: transparent transparent
            ${props => props.theme.colors.input} transparent;
        }
        position: absolute;
        top: 55px;
        right: 0;
        border: 0.5px solid ${props => props.theme.colors.input};
        background: ${props => props.theme.colors.background};
        -webkit-box-shadow: 0px 7px 15px rgb(0 0 0 / 7%);
        -moz-box-shadow: 0px 7px 15px rgb(0 0 0 / 7%);
        box-shadow: 0px 7px 15px rgb(0 0 0 / 7%);
        border-radius: 5px;
        width: 500px;
        z-index: 100000;
        .filter-content {
          width: 100%;
          height: 100%;
        }
      }
      .filter-search {
        background-color: #ffffff;
        box-shadow: 0px 7px 15px rgb(0 0 0 / 7%);
      }
    }

    .MuiBadge-badge {
      color: red;
      margin: 5px -2px;
    }
    .MuiBadge-anchorOriginTopRightRectangle {
      font-size: 20px;
      z-index: initial;
    }

    & .MuiFormControl-marginDense {
      margin: 0;
    }
    & .MuiOutlinedInput-input {
      padding-top: 9px;
      padding-bottom: 9px;
    }
    & .MuiOutlinedInput-root,
    .MuiOutlinedInput-root:focus {
      &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #2684ff;
      }
      border-radius: 50px;
      background: ${props => props.theme.colors.input} !important;
      color: ${props => props.theme.colors.placeholder} !important;
      border: 0.5px solid ${props => props.theme.colors.borderInput};
      outline: none;
    }
    & .MuiInputLabel-root {
      color: ${props => props.theme.colors.placeholder};
    }
    & .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 0.5px;
      border-color: #2684ff;
    }

    & .MuiFormControl-marginDense {
      margin: 0;
    }
    & .MuiOutlinedInput-input {
      padding-top: 9px;
      padding-bottom: 9px;
    }
    & .MuiOutlinedInput-root,
    .MuiOutlinedInput-root:focus {
      &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #2684ff;
      }
      border-radius: 5px;
      background: ${props => props.theme.colors.input} !important;
      color: ${props => props.theme.colors.placeholder};
      border: 0.5px solid ${props => props.theme.colors.borderInput};
      outline: none;
    }
    & .MuiInputLabel-root {
      color: ${props => props.theme.colors.placeholder};
    }
    & .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 0.5px;
      border-color: #2684ff;
    }
    /* TODO: end modifi */

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border: 0.5px solid transparent;
    }
    .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline {
      box-shadow: 0 0 0 0.5px #2684ff;
    }
    .MuiFormControl-root {
      background: ${props => props.theme.colors.input};
      border-radius: 5px;
    }
    .MuiInput-input {
      padding: 9px;
      border-bottom: none;
      color: ${props => props.theme.colors.placeholder};
    }
    .MuiInput-root {
      border-radius: 5px;
      border: 0.5px solid rgba(37, 75, 132, 0.1);
    }
    .MuiInput-root:focus-within {
      box-shadow: 0 0 0 1px #2684ff;
      border-color: transparent;
      .MuiInputBase-input {
      }
    }
    .MuiSvgIcon-root {
      color: ${props => props.theme.colors.placeholder};
      transition: all 0.3s;
    }
    .MuiCheckbox-root {
      .MuiSvgIcon-root {
        color: ${props => props.theme.colors.primary} !important;
        transition: all 0.3s;
      }
      .MuiTypography-root .MuiFormControlLabel-label .MuiTypography-body1 {
        color: ${props => props.theme.colors.title};
      }
    }
    .MuiInput-underline::before {
      content: none;
    }
    .MuiInput-underline::after {
      content: none;
    }

    &
      .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-fullWidth.secundaryButton {
      background: ${props => props.theme.colors.secundary};
      color: #f9f9f9;
      border-radius: 5px;
      /* border: 0.5px solid rgba(37, 75, 132, 0.25); */
      font-family: Poppins, sans-serif;
      box-shadow: none;
      font-weight: 500;
      text-transform: capitalize;
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-fullWidth.primaryButton {
      background: ${props => props.theme.colors.primary};
      color: #f9f9f9;
      border-radius: 5px;
      /* border: 0.5px solid rgba(37, 75, 132, 0.25); */
      font-family: Poppins, sans-serif;
      box-shadow: none;
      font-weight: 500;
      text-transform: capitalize;
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.secundaryButton {
      background: ${props => props.theme.colors.secundary};
      color: #f9f9f9;
      border-radius: 5px;
      /* border: 0.5px solid rgba(37, 75, 132, 0.25); */
      font-family: Poppins, sans-serif;
      box-shadow: none;
      font-weight: 500;
      text-transform: capitalize;
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.primaryButton {
      background: ${props => props.theme.colors.primary};
      color: #f9f9f9;
      border-radius: 5px;
      /* border: 0.5px solid rgba(37, 75, 132, 0.25); */
      font-family: Poppins, sans-serif;
      box-shadow: none;
      font-weight: 500;
      text-transform: capitalize;
    }

    .sel--is-disabled {
      .sel__control--is-disabled {
        /* background: ${props => props.theme.colors.input} !important; */
        background: #f2f2f2 !important;
        border-color: #e6e6e6 !important;
        /* color: ${props => props.theme.colors.placeholder} !important; */

        .sel__value-container {
          .sel__placeholder {
            color: #808080 !important;
          }
        }
      }
    }

    .sel__control {
      .sel__input {
        color: ${props => props.theme.colors.placeholderSelected} !important;
        font-size: 13px;
      }

      &:hover {
        border-color: ${props => props.theme.colors.borderInput} !important;
      }
    }
    .sel__menu {
      background: ${props => props.theme.colors.input};
      color: ${props => props.theme.colors.select_text};

      .sel__menu-list {
        font-size: 13px;
        .sel__option {
          color: ${props => props.theme.colors.select_text};
        }
        .sel__option--is-selected {
          background: ${props => props.theme.colors.primary};
          color: #ffffff;
        }

        .sel__option--is-focused {
          background: ${props => props.theme.colors.primary};
          color: ${props => props.theme.colors.text};
        }
      }
    }

    .input-wrapper input:focus {
      box-shadow: 0 0 0 0.5px #2684ff;
      border: 0.5px solid #2684ff;
    }

    .input-wrapper input::placeholder {
      font-size: 13px;
      line-height: 45px;
      font-weight: 400;
      color: ${props => props.theme.colors.placeholder};
    }
  }
`;

export const List = styled.div`
  background: #fff;
  border: 0.5px solid rgba(37, 75, 132, 0.25);
  border-radius: 5px;
`;

interface TableContainerProps {
  detailTable?: boolean;
}

export const Container = styled.div<TableContainerProps>`
  @media screen and (max-width: 1224px) {
    overflow-x: auto;
  }
  max-height: ${props => (props.detailTable ? '250px' : '100%')};
  overflow-y: auto;
  overflow-x: auto;

  .statusContainer {
    display: flex;
    justify-content: center;
  }

  .tableBoxId {
    tr {
      transition: background 0.3s ease;

      & + tr {
        td {
          padding: 16px;
          border: none;
        }
      }
    }
  }

  .table-box {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;

    thead,
    tbody {
      tr {
        white-space: nowrap;
        td,
        th {
          padding: 8px 8px;
        }
      }
    }

    thead {
      text-align: left;

      tr {
        th {
          .title {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            min-width: max-content;

            button {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              background: none;
              box-shadow: none;
              color: inherit;
              border: none;
              padding: 0;
              font: inherit;
              outline: inherit;
              margin: 0;
              width: 100%;
            }

            h1 {
              font-size: 13px;
              line-height: 18px;
              color: ${props => props.theme.colors.table_column_title};
            }

            .icon {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 8px;

              img {
                max-width: 100%;
              }
            }

            .left {
              flex-direction: row;
            }

            .right {
              flex-direction: row-reverse;
            }

            .center {
              flex-direction: row;
              justify-content: center;
            }
          }
        }
      }
    }

    tbody {
      padding: 0 1rem;
      tr {
        /* &:hover {
          background: rgba(0, 0, 0, 0.05);
        } */
        td {
          border-top: 1px solid ${props => props.theme.colors.table_row_line}; // #4C4C66

          /* & + td {
            border-left: 1px solid #e1e1e1;
          } */
          padding: 3px 0;

          &:last-child {
            width: 10%;
          }

          .boolean-item {
            .yes,
            .no {
              width: 65px;
              height: 26px;
              background: #def8e9;
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: center;

              p {
                font-weight: 500;
                font-size: 11px;
                line-height: 16px;
                color: #59de8f;
              }
            }

            .no {
              background: #f8dede;

              p {
                color: #ffa800;
              }
            }
          }

          .code-item {
            display: flex;
            align-items: center;

            p {
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #464e5f;
            }
          }

          .item {
            p {
              font-weight: 600;
              font-size: 13px;
              line-height: 19px;
              color: #b5b5c3;
            }
          }

          .detail {
            display: flex;
            justify-content: center;
          }

          .row-actions-item {
            display: flex;
            justify-content: flex-end;

            .action {
              width: 32px;
              height: 32px;
              border-radius: 8px;
              padding: 8px !important;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: transparent;
              position: relative;
              transition: 0.15s ease;

              .hover-item {
                visibility: hidden;
                position: absolute;
                opacity: 0;
                bottom: 40px;
                background: #000;
                padding: 8px 16px;
                border-radius: 4px;
                box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.1);
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
                color: #1e1e2d;
                left: 50%;
                transform: translateX(-50%) translateY(-5px);
                z-index: 10;
                transition: 0.3s;
              }

              &:hover {
                background-color: #f2f2f2;

                .hover-item {
                  opacity: 1;
                  visibility: visible;
                  transform: translateX(-50%) translateY(0);
                }
              }

              & + .action {
                margin-left: 10px;
              }

              img {
                max-width: 50%;
                border-radius: 8px;
              }
            }
          }

          .row-actions-button-detail {
            display: flex;
            justify-content: center;

            p {
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
            }

            .action {
              position: relative;
              width: 32px;
              height: 32px;
              display: flex;
              align-items: center;
              background: transparent;
              transition: 0.15s ease;
              margin-left: 0px !important;

              .hover-item {
                visibility: hidden;
                position: absolute;
                bottom: 40px;
                background: ${props =>
                  props.theme.title === 'light' ? '#EEF0F8' : '#191924'};
                padding: 8px 16px;
                border-radius: 4px;
                box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.1);
                opacity: 0;
                transform: translateX(-50%) translateY(-5px);
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
                color: ${props =>
                  props.theme.title === 'light' ? '#6C7293' : '#FFFFFF'};
                left: 50%;
                transform: translateX(-50%);
                transition: 0.15s ease;
              }

              &:hover {
                background: ${props =>
                  props.theme.title === 'light' ? '#f2f2f2' : '#222'};

                .hover-item {
                  opacity: 1;
                  visibility: visible;
                  transform: translateX(-50%) translateY(0);
                }
              }

              /* & + .action {
                margin-left: 10px;
              } */

              img {
                max-width: 50%;
              }
            }

            .actionDisable {
              position: relative;
              width: 32px;
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: transparent;
            }

            actionNone {
              position: relative;
              width: 0px;
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: transparent;
            }
          }

          .row-actions-button {
            display: flex;
            align-items: center;

            p {
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
            }

            .action {
              width: fit-content;
              height: 32px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: transparent;
              position: relative;

              .hover-item {
                display: none;
                position: absolute;
                bottom: 40px;
                background: #fff;
                padding: 8px 16px;
                border-radius: 4px;
                box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.1);
                -webkit-box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.1);
                -moz-box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.1);
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
                color: #1e1e2d;
                left: 50%;
                transform: translateX(-50%);
              }

              &:hover {
                transition: 0.5s;
                background: #f2f2f2;

                .hover-item {
                  display: block;
                }
              }

              & + .action {
              }

              img {
                max-width: 50%;
                border-radius: 8px;
              }
            }
          }
        }
      }
    }
  }
`;

export const SortButton = styled.button`
  background-color: inherit;
  justify-content: center;
  min-width: fit-content;
  align-content: flex-start;
  margin-left: 0 !important;
  img {
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-content: center;
  }
`;

interface CellContainerProps {
  boolean?: 'true' | 'false';
  positionPattern?: 'left' | 'right' | 'center';
  stylePattern?:
    | 'currency'
    | 'number'
    | 'date'
    | 'datetime'
    | 'time'
    | 'string'
    | 'colspan'
    | 'boolean'
    | 'datetime'
    | 'time'
    | 'float'
    | 'stringCenter';
}

export const CellContainer = styled.td<CellContainerProps>`
  max-width: 25%;
  min-width: 5%;
  width: 10%;

  div {
    display: flex;
    p {
      font-family: 'Poppins', sans-serif;
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      color: #b5b5c3; //
    }
  }

  ${props =>
    props.positionPattern &&
    css`
      text-align: ${props.positionPattern};
    `}

  ${props =>
    props.stylePattern === 'currency' &&
    css`
      min-width: 20%;
      width: 15%;
    `}

  ${props =>
    props.stylePattern === 'number' &&
    css`
      min-width: 20%;
      width: 10%;
    `}

  ${props =>
    ['date', 'datetime', 'time', 'number', 'stringCenter'].includes(
      props.stylePattern as string
    ) &&
    css`
      min-width: 20%;
      width: 15%;
      div {
        justify-content: center;
      }
    `}

  ${props =>
    props.stylePattern === 'string' &&
    css`
      min-width: 20%;
      width: 25%;
      justify-content: right;
    `}

  ${props =>
    props.stylePattern === 'boolean' &&
    css`
      max-width: 30%;

      div {
        width: 65px !important;
        height: 26px;
        background: #def8e9;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-weight: 500;
          font-size: 11px;
          line-height: 16px;
          color: #59de8f !important;
        }
      }
    `}

  ${props =>
    props.boolean === 'false' &&
    css`
      div {
        background: #f8dede;

        p {
          color: #de5959 !important;
        }
      }
    `}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    animation: ${rotate} 0.8s ease-in-out 0s infinite;
  }

  p {
    color: #1e1e2d;
    margin-top: 16px;
    font-size: 13px;
  }
`;
